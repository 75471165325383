import { FocusError } from "focus-formik-error";
import { customSelectTheme, styleObj } from "helpers/common";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import Tooltip from "rc-tooltip";
import { ChromePicker } from "react-color";
import Flatpickr from "react-flatpickr";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { openModal } from "slices/thunk";
import { onCloseSubject } from "Common/modals/Modal";
import { useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import Loader from "assets/images/spinner-white.svg";
import SignatureCanvas from "react-signature-canvas";

const renderImgList = (img: any, handleRemoveImg: any) => (
  <ul className="vstack gap-2 list-unstyled mb-3">
    <li>
      <div className="d-block p-2 bg-light bg-opacity-50 rounded">
        <div className="d-flex align-items-center gap-2">
          <div className="avatar-xs flex-shrink-0 ronded">
            <div className="avatar-title bg-body-secondary text-body">
              <i className="ri ri-image-line fs-lg"></i>
            </div>
          </div>
          <div className="flex-grow-1">
            <h6 className="mb-0 text-break">{URL.createObjectURL(img)}</h6>
          </div>
          <div className="flex-shrink-0 text-muted">
            <i
              className="ri-eye-fill cursor-pointer fs-2xl text-secondary ms-3"
              onClick={() => {
                window.open(URL.createObjectURL(img), "_blank");
              }}
            />
            <i
              className="ri-close-circle-fill cursor-pointer fs-2xl text-secondary ms-3"
              onClick={handleRemoveImg}
            />
          </div>
        </div>
      </div>
    </li>
  </ul>
);

const Step2Form = (props: any) => {
  const dispatch = useDispatch<any>();
  const sigCanvas = useRef<SignatureCanvas>(null);

  const { validation1, validation2, setactiveTab } = props;
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState<boolean>(false);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [error, setError] = useState<string>("");

  const shouldShowField = (fieldName: string) => {
    switch (fieldName) {
      case "agentName":
        return (
          validation1.values.country?.length > 0 &&
          validation1.values.country.includes("US")
        );
      case "testingNumber2":
      case "messageCount2":
      case "launchDate2":
        return (
          validation1.values.country?.length > 0 &&
          validation1.values.country.includes("US") &&
          !validation1.values.country.some((ct: string) => ct !== "US")
        );
      case "pCompanyWebsite":
        return (
          validation1.values.country?.length > 0 &&
          validation1.values.country.some((ct: string) => ct !== "US")
        );
      case "brandColor":
      case "agentDescription":
      case "cfPhone":
      case "cfPhoneLabel":
      case "cfEmail":
      case "cfEmailLabel":
      case "companyWebsite":
      case "companyWebsiteLabel":
      case "privacyPolicyLink":
      case "termsofServiceLink":
      case "traficSpike":
      case "agentBanner":
      case "agentLogo":
      case "signature":
        return validation1.values.country?.length > 0;
      case "traficSpikeTime":
        return (
          validation1.values.country?.length > 0 &&
          validation2.values.traficSpike === "Yes"
        );
      default:
        return false;
    }
  };

  const handleSubmit = () => {
    if (phoneNumber && isValidPhone) {
      validation2.setFieldValue("testingNumber2", [
        ...validation2.values.testingNumber2,
        phoneNumber,
      ]);
      setPhoneNumber("");
      setError("");
    } else {
      !phoneNumber
        ? setError("Please Enter Phone number")
        : !isValidPhone
        ? setError("Phone number is not valid")
        : setError("");
    }
  };

  const handleAcceptedFiles = (files: any, rejFiles: any, type: string) => {
    rejFiles?.length > 0 && console.log("rejFiles", rejFiles);
    rejFiles.forEach((file: any) => {
      file.errors.forEach((err: { code: string; message: string }) => {
        if (err.code === "file-too-large") {
          toast.error(
            err.message ||
              `File is larger than ${type === "agentBanner" ? "360kb" : "90kb"}`
          );
        }

        if (err.code === "file-invalid-type") {
          toast.error(err.message || "Something went wrong");
        }
      });
    });

    if (files?.length > 0) {
      type === "agentBanner" ? setIsLoading(true) : setIsLoading2(true);

      const handleSave = (file: any) => {
        type === "agentBanner" ? setIsLoading(false) : setIsLoading2(false);
        try {
          onCloseSubject.next({});
          validation2.setFieldValue(type, file);
        } catch (e) {}
      };

      const reader = new FileReader();
      if (!reader) return;
      reader.onload = () => {
        dispatch(
          openModal({
            path: "ImageCropper",
            data: {
              title: "Crop Image",
              footer: false,
              src: reader.result?.toString(),
              aspectRatio: type === "agentBanner" ? 45 / 14 : 1 / 1,
              handleSave,
              handleCancel: () => {
                onCloseSubject.next({});
                type === "agentBanner"
                  ? setIsLoading(false)
                  : setIsLoading2(false);
              },
            },
          })
        );
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const clear = () => {
    sigCanvas?.current?.clear();
  };

  const save = () => {
    const dataURL =
      sigCanvas?.current?.getTrimmedCanvas()?.toDataURL("image/png") || "";
    const [metadata, base64String] = dataURL.split(",");
    const contentType = metadata?.match(/data:(.*?);base64/)?.[1];
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(0)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    validation2.setFieldValue("signature", blob);
  };

  return (
    <div className="formContainer">
      <Form
        action="#"
        onSubmit={(e) => {
          e.preventDefault();
          validation2.handleSubmit();
        }}
      >
        <FocusError formik={validation2} />
        <div>
          <div className="mb-4">
            <div>
              <h5 className="mb-1">RBM Agent Details</h5>
              <p className="text-muted">
                Input data for the brand you'd like to register for RCS Business
                Messaging. Your branding information will be stored in Signal
                House and reflected on our templates.
              </p>
            </div>
          </div>
          <Row>
            {shouldShowField("agentName") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="agentName">
                  Agent Name (Display Name){" "}
                  <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="agentName"
                  onChange={validation2.handleChange}
                  value={validation2.values.agentName}
                  isInvalid={
                    validation2.touched.agentName &&
                    validation2.errors.agentName
                      ? true
                      : false
                  }
                />
                {validation2.touched.agentName &&
                validation2.errors.agentName ? (
                  <Form.Control.Feedback type="invalid">
                    {validation2.errors.agentName}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("agentDescription") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="agentDescription"
                  className="position-relative"
                >
                  Agent Description (shown to end-users){" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="This description will be visible to recipients and must be shorter than 100 characters"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="agentDescription"
                  onChange={validation2.handleChange}
                  value={validation2.values.agentDescription}
                  isInvalid={
                    validation2.touched.agentDescription &&
                    validation2.errors.agentDescription
                      ? true
                      : false
                  }
                />
                {validation2.touched.agentDescription &&
                validation2.errors.agentDescription ? (
                  <Form.Control.Feedback type="invalid">
                    {validation2.errors.agentDescription}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("brandColor") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="brandColor" className="position-relative">
                  Brand Color (Hex Code, #FFFFFF format){" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay={
                      <p className="mb-0">
                        Please submit in Hex format (#6868AB). This color will
                        change the Call/Website/Email icons; for white, the
                        Contrast Ratio must be at least 4.5:1. You can check
                        that{" "}
                        <a href="https://webaim.org/resources/contrastchecker/">
                          Here.
                        </a>
                      </p>
                    }
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type="text"
                    name="brandColor"
                    value={validation2.values.brandColor}
                    isInvalid={
                      validation2.touched.brandColor &&
                      validation2.errors.brandColor
                        ? true
                        : false
                    }
                    pattern="^#([A-Fa-f0-9]{6})$"
                    onClick={() => setShowColorPicker(!showColorPicker)}
                  />
                  {showColorPicker && (
                    <div
                      className="position-absolute"
                      style={{ zIndex: 2 }}
                      ref={(node) => {
                        if (node) {
                          const handleClickOutside = (e: MouseEvent) => {
                            const target = e.target as Element;
                            // Check if click is on color picker or its children
                            if (target.closest(".chrome-picker")) {
                              return;
                            }

                            // Check if click is outside picker and input
                            if (
                              !node.contains(e.target as Node) &&
                              !target.closest('input[name="brandColor"]')
                            ) {
                              setShowColorPicker(false);
                            }
                          };
                          document.addEventListener(
                            "mousedown",
                            handleClickOutside
                          );
                          return () =>
                            document.removeEventListener(
                              "mousedown",
                              handleClickOutside
                            );
                        }
                      }}
                    >
                      <ChromePicker
                        color={validation2.values.brandColor || "#FFFFFF"}
                        onChange={(color: any) => {
                          validation2.setFieldValue("brandColor", color.hex);
                        }}
                        disableAlpha={true}
                      />
                    </div>
                  )}
                  {validation2.touched.brandColor &&
                  validation2.errors.brandColor ? (
                    <Form.Control.Feedback type="invalid">
                      {validation2.errors.brandColor}
                    </Form.Control.Feedback>
                  ) : null}
                </div>
              </Col>
            )}
            {shouldShowField("traficSpike") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="traficSpike" className="position-relative">
                  Will Traffic Spikes at a Certain Time of Day?{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="Are there certain times where messages will occur at a higher frequency? If so, provide a time range"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <div className="d-flex">
                  <Select
                    name="traficSpike"
                    styles={styleObj(
                      validation2.touched.traficSpike &&
                        validation2.errors.traficSpike
                    )}
                    className="w-100"
                    theme={customSelectTheme}
                    options={[
                      {
                        label: "Yes",
                        value: "Yes",
                      },
                      {
                        label: "No",
                        value: "No",
                      },
                    ]}
                    onChange={(e: { value: string }) =>
                      validation2.setFieldValue("traficSpike", e.value)
                    }
                    value={{
                      label: validation2.values.traficSpike,
                      value: validation2.values.traficSpike,
                    }}
                  />
                  {shouldShowField("traficSpikeTime") && (
                    <Flatpickr
                      className={`form-control ms-1 ${
                        validation2.touched.traficSpikeTime &&
                        validation2.errors.traficSpikeTime
                          ? "is-invalid"
                          : ""
                      }`}
                      name="traficSpikeTime"
                      options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "h:i K",
                        time_24hr: false,
                        onChange: (
                          value: any,
                          dateStr: string,
                          instance: any
                        ) => {
                          validation2.setFieldValue(
                            "traficSpikeTime",
                            dateStr || ""
                          );
                        },
                      }}
                      value={validation2.values.traficSpikeTime || ""}
                    />
                  )}
                </div>
                {validation2.touched.traficSpike &&
                validation2.errors.traficSpike ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation2.errors.traficSpike}
                  </Form.Control.Feedback>
                ) : null}
                {validation2.touched.traficSpikeTime &&
                validation2.errors.traficSpikeTime ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation2.errors.traficSpikeTime}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("cfPhone") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="cfPhone" className="position-relative">
                  Customer Facing Phone Number{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="This number will be visible to message recipients—we recommend using a support or sales number."
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="cfPhone"
                  onChange={validation2.handleChange}
                  value={validation2.values.cfPhone}
                  isInvalid={
                    validation2.touched.cfPhone && validation2.errors.cfPhone
                      ? true
                      : false
                  }
                />
                {validation2.touched.cfPhone && validation2.errors.cfPhone ? (
                  <Form.Control.Feedback type="invalid">
                    {validation2.errors.cfPhone}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("cfPhoneLabel") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="cfPhoneLabel"
                  className="position-relative"
                >
                  Customer Facing Phone Number Label{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="Phone number label, will be visible to message recipients"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="cfPhoneLabel"
                  onChange={validation2.handleChange}
                  value={validation2.values.cfPhoneLabel}
                  isInvalid={
                    validation2.touched.cfPhoneLabel &&
                    validation2.errors.cfPhoneLabel
                      ? true
                      : false
                  }
                />
                {validation2.touched.cfPhoneLabel &&
                validation2.errors.cfPhoneLabel ? (
                  <Form.Control.Feedback type="invalid">
                    {validation2.errors.cfPhoneLabel}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("cfEmail") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="cfEmail" className="position-relative">
                  Customer Facing Email Address{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="This email will be visible to message recipients—we recommend using a support or sales email."
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="cfEmail"
                  onChange={validation2.handleChange}
                  onBlur={validation2.handleBlur}
                  value={validation2.values.cfEmail}
                  isInvalid={
                    validation2.touched.cfEmail && validation2.errors.cfEmail
                      ? true
                      : false
                  }
                />
                {validation2.touched.cfEmail && validation2.errors.cfEmail ? (
                  <Form.Control.Feedback type="invalid">
                    {validation2.errors.cfEmail}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("cfEmailLabel") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="cfEmailLabel"
                  className="position-relative"
                >
                  Customer Facing Email Address Label{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="Email label, will be visible to message recipients"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="cfEmailLabel"
                  onChange={validation2.handleChange}
                  value={validation2.values.cfEmailLabel}
                  isInvalid={
                    validation2.touched.cfEmailLabel &&
                    validation2.errors.cfEmailLabel
                      ? true
                      : false
                  }
                />
                {validation2.touched.cfEmailLabel &&
                validation2.errors.cfEmailLabel ? (
                  <Form.Control.Feedback type="invalid">
                    {validation2.errors.cfEmailLabel}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("companyWebsite") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="companyWebsite"
                  className="position-relative"
                >
                  Official Company Website{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="Customer-facing website, will be visible to message recipients"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="url"
                  name="companyWebsite"
                  onChange={validation2.handleChange}
                  onBlur={validation2.handleBlur}
                  value={validation2.values.companyWebsite}
                  isInvalid={
                    validation2.touched.companyWebsite &&
                    validation2.errors.companyWebsite
                      ? true
                      : false
                  }
                />
                {validation2.touched.companyWebsite &&
                validation2.errors.companyWebsite ? (
                  <Form.Control.Feedback type="invalid">
                    {validation2.errors.companyWebsite}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("companyWebsiteLabel") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="companyWebsiteLabel"
                  className="position-relative"
                >
                  Label for Company Website{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="Customer-facing website label"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="companyWebsiteLabel"
                  onChange={validation2.handleChange}
                  value={validation2.values.companyWebsiteLabel}
                  isInvalid={
                    validation2.touched.companyWebsiteLabel &&
                    validation2.errors.companyWebsiteLabel
                      ? true
                      : false
                  }
                />
                {validation2.touched.companyWebsiteLabel &&
                validation2.errors.companyWebsiteLabel ? (
                  <Form.Control.Feedback type="invalid">
                    {validation2.errors.companyWebsiteLabel}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("privacyPolicyLink") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="privacyPolicyLink"
                  className="position-relative"
                >
                  Privacy Policy URL <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="URL must be clickable, but can be a webpage or a PDF"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="url"
                  name="privacyPolicyLink"
                  onChange={validation2.handleChange}
                  onBlur={validation2.handleBlur}
                  value={validation2.values.privacyPolicyLink}
                  isInvalid={
                    validation2.touched.privacyPolicyLink &&
                    validation2.errors.privacyPolicyLink
                      ? true
                      : false
                  }
                />
                {validation2.touched.privacyPolicyLink &&
                validation2.errors.privacyPolicyLink ? (
                  <Form.Control.Feedback type="invalid">
                    {validation2.errors.privacyPolicyLink}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("termsofServiceLink") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="termsofServiceLink"
                  className="position-relative"
                >
                  Terms of Service URL <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="URL must be clickable, but can be a webpage or a PDF"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="url"
                  name="termsofServiceLink"
                  onChange={validation2.handleChange}
                  onBlur={validation2.handleBlur}
                  value={validation2.values.termsofServiceLink}
                  isInvalid={
                    validation2.touched.termsofServiceLink &&
                    validation2.errors.termsofServiceLink
                      ? true
                      : false
                  }
                />
                {validation2.touched.termsofServiceLink &&
                validation2.errors.termsofServiceLink ? (
                  <Form.Control.Feedback type="invalid">
                    {validation2.errors.termsofServiceLink}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("pCompanyWebsite") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="pCompanyWebsite"
                  className="position-relative"
                >
                  Parent Company Website (if applicable){" "}
                  <span className="text-primary">*</span>
                </Form.Label>
                <Form.Control
                  type="url"
                  name="pCompanyWebsite"
                  onChange={validation2.handleChange}
                  onBlur={validation2.handleBlur}
                  value={validation2.values.pCompanyWebsite}
                  isInvalid={
                    validation2.touched.pCompanyWebsite &&
                    validation2.errors.pCompanyWebsite
                      ? true
                      : false
                  }
                />
                {validation2.touched.pCompanyWebsite &&
                validation2.errors.pCompanyWebsite ? (
                  <Form.Control.Feedback type="invalid">
                    {validation2.errors.pCompanyWebsite}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("testingNumber2") && (
              <Col lg={6} className="mb-3">
                <div className="">
                  <Form.Label
                    htmlFor="testingNumber2"
                    className="position-relative"
                  >
                    Number Used for Testing{" "}
                    <span className="text-primary">*</span>
                    <Tooltip
                      placement="bottom"
                      overlay="Mobile Phone numbers that will be used to test the RCS rollout; international format (+15551236739)"
                    >
                      <i
                        className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                        style={{ bottom: -2 }}
                      />
                    </Tooltip>
                  </Form.Label>
                  {validation2.errors.testingNumber2 ? (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block mb-1 text-primary"
                    >
                      {validation2.errors.testingNumber2}
                    </Form.Control.Feedback>
                  ) : null}
                  <div className="d-flex">
                    <Form.Control
                      type="number"
                      name="testingNumber2"
                      placeholder="Enter phone number"
                      onChange={(e) => {
                        const reg = /^[1-9]\d{1,3}[1-9]\d{6,14}$/;
                        const val = e.target.value;
                        const isValid = reg.test(val);

                        !isValid
                          ? setError("Phone Number is Not Valid")
                          : setError("");

                        setPhoneNumber(val);
                        setIsValidPhone(isValid);
                      }}
                      value={phoneNumber || ""}
                    />
                    <Button
                      variant="secondary"
                      className="ms-2 d-flex align-items-center"
                      onClick={handleSubmit}
                    >
                      <i className="bx bx-plus me-1"></i> Add
                    </Button>
                  </div>
                  {error ? (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {error}
                    </Form.Control.Feedback>
                  ) : null}
                  <div className="d-flex flex-wrap mt-2">
                    {validation2.values.testingNumber2?.map(
                      (dt: string, i2: number) => {
                        return (
                          <span
                            className="details-tag mt-1 mr-2 d-flex align-items-center"
                            key={i2}
                          >
                            {dt}
                            <i
                              className="ri-close-circle-fill cursor-pointer fs-md text-primary ms-2"
                              onClick={() => {
                                const temp = [
                                  ...validation2.values.testingNumber2,
                                ];
                                temp.splice(i2, 1);
                                validation2.setFieldValue(
                                  "testingNumber2",
                                  temp
                                );
                              }}
                            />
                          </span>
                        );
                      }
                    )}
                  </div>
                </div>
              </Col>
            )}
            {shouldShowField("messageCount2") && (
              <Col lg={6} className="mb-3">
                <Form.Label
                  htmlFor="messageCount2"
                  className="position-relative"
                >
                  Estimated Monthly Messages{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="This figure includes the number of users multiplied by the average number of messages per user; an estimation is acceptable"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="number"
                  name="messageCount2"
                  onChange={validation2.handleChange}
                  onBlur={validation2.handleBlur}
                  value={validation2.values.messageCount2 || ""}
                  isInvalid={
                    validation2.touched.messageCount2 &&
                    validation2.errors.messageCount2
                      ? true
                      : false
                  }
                />
                {validation2.touched.messageCount2 &&
                validation2.errors.messageCount2 ? (
                  <Form.Control.Feedback type="invalid">
                    {validation2.errors.messageCount2}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {shouldShowField("launchDate2") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="launchDate2" className="position-relative">
                  Target Launch Date <span className="text-primary">*</span>
                </Form.Label>
                <Flatpickr
                  className={`form-control ${
                    validation2.touched.launchDate2 &&
                    validation2.errors.launchDate2
                      ? "is-invalid"
                      : ""
                  }`}
                  name="launchDate2"
                  options={{
                    enableTime: false,
                    altFormat: "F j, Y",
                    dateFormat: "m-d-Y",
                    onChange: (value: any, dateStr: string, instance: any) => {
                      validation2.setFieldValue("launchDate2", dateStr || "");
                    },
                  }}
                  value={validation2.values.launchDate2 || ""}
                />
                {validation2.touched.launchDate2 &&
                validation2.errors.launchDate2 ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {validation2.errors.launchDate2}
                  </Form.Control.Feedback>
                ) : null}
              </Col>
            )}
            {validation1.values.country.includes("US") && (
              <Col lg={6} className="mb-3" />
            )}
            {shouldShowField("agentBanner") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="agentBanner" className="position-relative">
                  Agent Banner <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay={`Hero image, this will be shown in the "Agent Information" screen. Must be a JPG file and be exactly 1440 x 448 px. No larger than 360 kb`}
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                {validation2.errors.agentBanner ? (
                  <p className="text-primary">
                    {validation2.errors.agentBanner}
                  </p>
                ) : null}
                {validation2.values.agentBanner &&
                  renderImgList(validation2.values.agentBanner, () =>
                    validation2.setFieldValue("agentBanner", null)
                  )}
                <Dropzone
                  onDrop={(acceptedFiles: any, fileRejections: any) => {
                    handleAcceptedFiles(
                      acceptedFiles,
                      fileRejections,
                      "agentBanner"
                    );
                  }}
                  maxSize={368640} // 360kB
                  multiple={false}
                  accept={{
                    "image/*": [".jpeg", ".jpg", ".png"],
                  }}
                >
                  {({ getRootProps }: any) => (
                    <div className="position-relative dropzone dz-clickable text-center border rounded">
                      {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center position-absolute start-0 end-0 top-0 bottom-0 bg-dark bg-opacity-50">
                          <img
                            src={Loader}
                            alt="loading"
                            className="img-thumbnail border-0 bg-transparent"
                          />
                        </div>
                      ) : null}
                      <div
                        className="dz-message needsclick cursor-pointer"
                        {...getRootProps()}
                      >
                        <div className="mb-3">
                          <i className="ri-camera-fill text-muted fs-8xl" />
                        </div>
                        <p className="fs-lg">
                          Drop a file to upload or click to upload (1440 X 448)
                        </p>
                        <p className="fs-md text-muted">
                          Maximum upload file size: 360kb
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Col>
            )}
            {shouldShowField("agentLogo") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="agentLogo" className="position-relative">
                  Agent Primary Logo Image{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="This logo will be displayed on all RCS Agent screens, and next to messages in the person's phone (as a 'contact photo'). The image must be a JPG file and ahve exactly 224 x 224 px. No larger than 90 kb"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                {validation2.errors.agentLogo ? (
                  <p className="text-primary">{validation2.errors.agentLogo}</p>
                ) : null}
                {validation2.values.agentLogo &&
                  renderImgList(validation2.values.agentLogo, () =>
                    validation2.setFieldValue("agentLogo", null)
                  )}
                <Dropzone
                  onDrop={(acceptedFiles: any, fileRejections: any) => {
                    handleAcceptedFiles(
                      acceptedFiles,
                      fileRejections,
                      "agentLogo"
                    );
                  }}
                  maxSize={92160} // 90kB
                  multiple={false}
                  accept={{
                    "image/*": [".jpeg", ".jpg", ".png"],
                  }}
                >
                  {({ getRootProps }: any) => (
                    <div className="position-relative dropzone dz-clickable text-center border rounded">
                      {isLoading2 ? (
                        <div className="d-flex justify-content-center align-items-center position-absolute start-0 end-0 top-0 bottom-0 bg-dark bg-opacity-50">
                          <img
                            src={Loader}
                            alt="loading"
                            className="img-thumbnail border-0 bg-transparent"
                          />
                        </div>
                      ) : null}
                      <div
                        className="dz-message needsclick cursor-pointer"
                        {...getRootProps()}
                      >
                        <div className="mb-3">
                          <i className="ri-camera-fill text-muted fs-8xl" />
                        </div>
                        <p className="fs-lg">
                          Drop a file to upload or click to upload (224 X 224)
                        </p>
                        <p className="fs-md text-muted">
                          Maximum upload file size: 90kb
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Col>
            )}
            {shouldShowField("signature") && (
              <Col lg={6} className="mb-3">
                <Form.Label htmlFor="signature" className="position-relative">
                  Signature for Attestation Document{" "}
                  <span className="text-primary">*</span>
                  <Tooltip
                    placement="bottom"
                    overlay="By drawing your signature here you attest that you own or are authorized to act on behalf of the Brand and/or Legal Company mentioned in this RBM Agent submission"
                  >
                    <i
                      className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                      style={{ bottom: -2 }}
                    />
                  </Tooltip>
                </Form.Label>
                {validation2.errors.signature ? (
                  <p className="text-primary">{validation2.errors.signature}</p>
                ) : null}
                {validation2.values.signature &&
                  renderImgList(validation2.values.signature, () =>
                    validation2.setFieldValue("signature", null)
                  )}
                <div className="position-relative">
                  <SignatureCanvas
                    ref={sigCanvas}
                    penColor="black"
                    canvasProps={{
                      height: 200,
                      className: "rounded border w-100",
                    }}
                  />
                </div>
                <div className="hstack gap-2 justify-content-end mt-2">
                  <Button className="btn btn-outline-primary" onClick={clear}>
                    Clear
                  </Button>
                  <Button variant="primary" onClick={save}>
                    Save Signature
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </div>
        <div className="d-flex align-items-start justify-content-center gap-3 mt-4">
          <button
            type="button"
            className="btn btn-link text-decoration-none btn-label previestab"
            onClick={() => setactiveTab(0)}
          >
            <i className="ri-arrow-left-line label-icon align-middle fs-lg"></i>
            Back
          </button>
          <button
            type="submit"
            // disabled={loading2}
            className="btn btn-secondary btn-label right nexttab nexttab"
          >
            <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
            {/* {loading2 && <Spinner size="sm" animation="border" />}  */}
            Submit
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Step2Form;
