import { Tab } from "react-bootstrap";
import MobilePreview from "./MobilePreview";
import Step2Form from "./Step2Form";

const Step2 = (props: any) => {
  return (
    <Tab.Pane
      eventKey="1"
      id="pills-info-desc"
      role="tabpanel"
      aria-labelledby="pills-info-desc-tab"
    >
      <div className="rbmContainer">
        <Step2Form {...props} />
        <MobilePreview {...props} />
      </div>
    </Tab.Pane>
  );
};

export default Step2;
