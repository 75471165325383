import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const scrollToElement = (elementId: string) => {
  setTimeout(function () {
    const simpleBarElement = document.querySelector(
      ".simplebar-scrollbar1 .simplebar-content-wrapper"
    ) as HTMLElement;
    const targetElement = document.getElementById(elementId);

    if (simpleBarElement && targetElement) {
      const offsetTop = targetElement.offsetTop - simpleBarElement.offsetTop;
      simpleBarElement.scrollTo({
        top: offsetTop - 100,
        behavior: "smooth",
      });
    }
  }, 200);
};

const Navdata = () => {
  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isNumbers, setIsNumbers] = useState(false);
  const [isCampaigns, setIsCampaigns] = useState(false);
  const [isSendMessage, setIsSendMessage] = useState(false);
  const [isDeveloperTools, setIsDeveloperTools] = useState(false);

  const selector = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      groupDetails: groups.AllGroups,
    })
  );

  const { groupDetails } = useSelector(selector);

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");

    if (iscurrentState !== "Numbers") {
      setIsNumbers(false);
    }

    if (iscurrentState !== "Campaigns") {
      setIsCampaigns(false);
    }

    if (iscurrentState !== "Send Message") {
      setIsSendMessage(false);
    }

    if (iscurrentState !== "Developer Tools") {
      setIsDeveloperTools(false);
    }

    // to scroll after opening menu
    isNumbers && scrollToElement("numbers");
    isCampaigns && scrollToElement("campaigns");
    isSendMessage && scrollToElement("send-message");
    isDeveloperTools && scrollToElement("developer-tools");
  }, [iscurrentState, isNumbers, isCampaigns, isSendMessage, isDeveloperTools]);

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id: string = item.getAttribute("sub-items");
        var menusId = document.getElementById(id);
        if (menusId) {
          (menusId.parentElement as HTMLElement).classList.remove("show");
        }
      });
      e.target.classList.add("active");
    }
  }

  const menuItems: any = [
    // {
    //   id: "search",
    //   label: "Search",
    //   icon: "ph-magnifying-glass",
    //   link: "/#",
    // },
    {
      label: "Menu",
      isHeader: true,
    },
    // {
    //   id: "home",
    //   label: "Home",
    //   icon: "ph-house",
    //   link: "/home",
    // },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ph-house",
      link: "/dashboard",
      uid: "dashboard",
    },
    {
      id: "settings",
      label: "Settings",
      icon: "ph-gear-six",
      link: "/settings",
      uid: "settings",
    },
    {
      id: "developer-tools",
      label: "Developer Tools",
      icon: "ph-wrench",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsDeveloperTools(!isDeveloperTools);
        setIscurrentState("Developer Tools");
        updateIconSidebar(e);
      },
      stateVariables: isDeveloperTools,
      subItems: [
        {
          id: "api-keys",
          label: "API Keys",
          link: "/api-keys",
          parentId: "developer-tools",
          uid: "api-keys",
        },
        {
          id: "api-documentation",
          label: "API Documentation",
          link: `/apidoc`,
          target: "_blank",
          parentId: "developer-tools",
          uid: "api-documentation",
        },
        {
          id: "webhooks",
          label: "Webhooks",
          link: "/webhooks",
          parentId: "developer-tools",
          uid: "webhooks",
        },
        {
          id: "short-url",
          label: "URL Shortener",
          link: "/short-url",
          parentId: "developer-tools",
          uid: "short-url",
        },
      ],
    },
    {
      id: "groups",
      label: "Sub Groups",
      icon: "ph-users-three",
      link: `/groups/${groupDetails?.records?.[0]?.group_id}/subgroups`,
      uid: "groups",
    },
    {
      id: "numbers",
      label: "Numbers",
      icon: "ph-file-text",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsNumbers(!isNumbers);
        setIscurrentState("Numbers");
        updateIconSidebar(e);
      },
      stateVariables: isNumbers,
      subItems: [
        {
          id: "own-numbers",
          label: "Your Numbers",
          link: "/mynumbers",
          parentId: "numbers",
          uid: "own-numbers",
        },
        {
          id: "buy-a-number",
          label: "Buy a Number",
          link: "/buynumbers",
          parentId: "numbers",
          uid: "buy-a-number",
        },
        {
          id: "release-number",
          label: "Released Phone Numbers",
          link: "/release-number",
          parentId: "numbers",
          uid: "release-number",
        },
        // {
        //   id: "verified-caller-ids",
        //   label: "Verified Caller IDs",
        //   link: "/verified-caller-ids",
        //   parentId: "numbers",
        // },
        {
          id: "port-a-number",
          label: "Port a Number",
          link: "/port-numbers",
          parentId: "numbers",
          uid: "port-a-number",
        },
        // {
        //   id: "phone-number-lookup",
        //   label: "Phone Number Lookup",
        //   link: "/number-lookup",
        //   parentId: "numbers",
        // },
      ],
    },
    {
      id: "analytics",
      label: "Analytics",
      icon: "ph-chart-line-up",
      link: "/analytics",
      uid: "analytics",
    },
    {
      id: "message-logs",
      label: "Message Logs",
      icon: "ph-clipboard-text",
      link: "/message-logs",
      uid: "message-logs",
    },
    {
      id: "campaigns",
      label: "10DLC Registration",
      icon: "ph-megaphone",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsCampaigns(!isCampaigns);
        setIscurrentState("Campaigns");
        updateIconSidebar(e);
      },
      stateVariables: isCampaigns,
      subItems: [
        {
          id: "brands",
          label: "Brands",
          link: "/brands",
          parentId: "campaigns",
          uid: "brands",
        },
        {
          id: "campaigns-child",
          label: "Campaigns",
          link: "/campaigns",
          parentId: "campaigns",
          uid: "campaigns-child",
        },
      ],
    },
    {
      id: "send-message",
      label: "Send Message",
      icon: "ph-chat-circle-text",
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setIsSendMessage(!isSendMessage);
        setIscurrentState("Send Message");
        updateIconSidebar(e);
      },
      stateVariables: isSendMessage,
      subItems: [
        {
          id: "send-sms",
          label: "Send SMS",
          link: "/send-sms",
          parentId: "send-message",
          uid: "send-sms",
        },
        {
          id: "send-mms",
          label: "Send MMS",
          link: "/send-mms",
          parentId: "send-message",
          uid: "send-mms",
        },
      ],
    },
    {
      id: "help-center",
      label: "Help Center",
      link: "/help-center",
      icon: "ph-question",
      uid: "help-center",
    },
    {
      id: "marketplace",
      label: "Marketplace",
      link: "/marketplace",
      icon: "ph-shopping-cart",
      uid: "marketplace",
    },
    // {
    //   id: "message-template",
    //   label: "Message Template",
    //   icon: "ph-article",
    //   link: "/message-template",
    // },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;
