// @ts-nocheck

import React, { useEffect, useState, useCallback, useRef } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { createSelector } from "reselect";
import withRouter from "Common/withRouter";
import BreadCrumb from "Common/BreadCrumb";
// import Loader from "components/Loader";
import DLC from "./DLC";
import Tooltip from "rc-tooltip";

import {
  GetConfiguredNumber,
  configureNumbers,
  getDetailsOfOwnNumber,
  getSubgroupDetailsThunk,
  tenDLCWhitelistThunk,
} from "slices/thunk";
import {
  asyncSubgroupList,
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { fetchCampaignDetailsApi } from "helpers/apiroute_helper";
import axios from "axios";

const selectAccount = createSelector(
  (state) => state.Groups,
  (state) => state.ConfigureNumbers,
  (state) => state.Numbers,
  (state: any) => state.Campaigns,
  (groups, configureNumbers, numbers, campaigns) => ({
    groupDetails: groups?.AllGroups,
    subgroupDetails: groups.subgroupDetails,
    configNumberDetails: configureNumbers?.data,
    phoneNumberDetails: numbers?.numberDetails,
    campaignsData: campaigns.campaignsData,
  })
);

const validationSchema = Yup.object().shape({
  groupId: Yup.string().required("Group is required"),
  // subGroupId: Yup.array()
  //   .min(1, "At least one subgroup must be selected")
  //   .required("Subgroups are required"),
  urlOne_Message: Yup.string().url("Invalid URL").notRequired(),
  urlTwo_Message: Yup.string().url("Invalid URL").notRequired(),
});

const dlcValidationSchema = Yup.object().shape({
  brandId: Yup.string().when("isCnpCampaign", {
    is: false,
    then: () => Yup.string().required("Please select a brand"),
    otherwise: () => Yup.string().notRequired(),
  }),
  campaignId: Yup.string().required("Please select a campaign"),
});

const ConfigureNumbers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { phoneNumber, sid } = useParams();
  const hasSetValues = useRef(false);

  const {
    groupDetails,
    subgroupDetails,
    configNumberDetails,
    phoneNumberDetails,
    campaignsData,
  } = useSelector(selectAccount);

  const [loading, setLoading] = useState(false);
  const [subgroupTemp, setSubgroupTemp] = useState([]);
  const [isCnpCampaign, setIsCnpCampaign] = useState(false);
  const [brandTemp, setBrandTemp] = useState({});
  const [campaignTemp, setCampaignTemp] = useState({});
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubgrpCalling, setIsSubgrpCalling] = useState(false);

  const initialValues = {
    sid,
    phoneNumber,
    groupId: groupDetails?.records?.[0]?.group_id || "",
    subGroupId: [],
    aMessageComesIn_Message: "webhook",
    urlOne_Message: "",
    primaryHandlerFails_Message: "webhook",
    urlTwo_Message: "",
  };

  const dlcInitialValues = {
    brandId: "",
    campaignId: "",
    isCnpCampaign: false,
    usecase: "",
    description: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values, dlcFormik.values);
    },
  });

  const dlcFormik = useFormik({
    initialValues: dlcInitialValues,
    validationSchema: dlcValidationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      onSubmit(formik.values, values);
    },
  });

  const onSubmit = useCallback(
    async (values) => {
      const dlcValues = dlcFormik.values;

      if (phoneNumberDetails?.brandId !== dlcValues?.brandId) {
        // Validate DLC formik first
        const dlcErrors = await dlcFormik.validateForm();

        if (Object.keys(dlcErrors).length > 0) {
          // Set focus on the first error field
          const firstErrorField = Object.keys(dlcErrors)[0];
          if (firstErrorField) {
            const errorElement = document.getElementById(firstErrorField);

            if (errorElement) {
              errorElement.focus();
            }
          }

          // If there are validation errors, set them and stop further processing
          dlcFormik.setErrors(dlcErrors);
          dlcFormik.setTouched(
            Object.keys(dlcErrors).reduce((acc, key) => {
              acc[key] = true;
              return acc;
            }, {})
          );
          return;
        }
      }

      setIsSubmit(true);

      const handleDLCSubmit = async () => {
        const whitelistPayload = {
          phoneNumber: String(phoneNumber),
          campaignId: dlcFormik?.values?.campaignId || "",
          usecase: dlcFormik?.values?.usecase || campaignDetails?.usecase || "",
          description:
            dlcFormik?.values?.description ||
            campaignDetails?.description ||
            "",
        };

        dispatch(
          tenDLCWhitelistThunk(removeEmptyAndNullValues(whitelistPayload))
        )
          .then(() => {
            setIsSubmit(false);
          })
          .catch(() => {
            setIsSubmit(false);
          });
      };

      // If DLC validation passes or brand ID hasn't changed, proceed with the rest of the logic

      const updateConfigAndDetails = () => {
        setIsSubmit(true);
        const updatedPayload = {
          sid,
          phoneNumber,
          groupId: values.groupId,
          subGroupId: values.subGroupId?.map((dt) => dt?.sub_group_id),
          messagingPrimaryUrl: values.urlOne_Message,
          messagingSecondaryUrl: values.urlTwo_Message,
          brandId: dlcValues?.isCnpCampaign ? null : dlcValues?.brandId,
          campaignId: dlcValues?.campaignId,
        };

        dispatch(configureNumbers(updatedPayload))
          .then(() => {
            // dispatch(getDetailsOfOwnNumber({ phoneNumber })).then(() => {
            if (
              (phoneNumberDetails?.campaignId &&
                phoneNumberDetails?.brandId !== dlcValues?.brandId) ||
              phoneNumberDetails?.campaignId !== dlcValues?.campaignId ||
              phoneNumberDetails?.subGroupId?.[0] !== dlcValues?.subGroupId?.[0]
            ) {
              handleDLCSubmit();
            } else {
              setIsSubmit(false);
            }
          })
          .catch(() => {
            setIsSubmit(false);
          });
      };

      updateConfigAndDetails();

      // if (
      //   phoneNumberDetails?.brandId !== brandTemp?.brandId ||
      //   phoneNumberDetails?.campaignId !== campaignTemp?.campaignId
      // ) {
      //   console.log(whitelistPayload, "whitelistPayload");
      // } else {
      //   updateConfigAndDetails();
      // }
    },
    [
      dispatch,
      phoneNumber,
      sid,
      isCnpCampaign,
      brandTemp,
      campaignTemp,
      phoneNumberDetails,
      campaignDetails,
      dlcFormik?.values,
      formik?.values,
      campaignDetails,
    ]
  );

  useEffect(() => {
    dlcFormik.validateForm();
  }, [dlcFormik.values]);

  useEffect(() => {
    if (phoneNumber) {
      setLoading(true);
      dispatch(GetConfiguredNumber({ phoneNumber })).finally(() => {
        setLoading(false);
      });
    }
  }, [dispatch, phoneNumber]);

  useEffect(() => {
    setIsSubgrpCalling(false);
    if (subgroupDetails?.data?.subGroupData?.length > 0) {
      setSubgroupTemp([subgroupDetails?.data?.subGroupData?.[0]]);
      formik.setFieldValue("subGroupId", [
        subgroupDetails?.data?.subGroupData?.[0],
      ]);
    } else {
      setSubgroupTemp([]);
      formik.setFieldValue("subGroupId", []);
    }
  }, [subgroupDetails]);

  useEffect(() => {
    if (
      !hasSetValues.current &&
      configNumberDetails &&
      groupDetails?.records?.[0]?.group_id &&
      Object.keys(configNumberDetails)?.length > 0
    ) {
      formik.setValues({
        ...formik.values,
        groupId: groupDetails?.records?.[0]?.group_id || "",
        aMessageComesIn_Message:
          configNumberDetails?.aMessageComesIn?.[0]?.aMessageComesWith ||
          "webhook",
        urlOne_Message: configNumberDetails?.aMessageComesIn?.[0]?.url || "",
        primaryHandlerFails_Message:
          configNumberDetails?.primaryHandler?.[0]?.primaryHandlerFails ||
          "webhook",
        urlTwo_Message: configNumberDetails?.primaryHandler?.[0]?.url || "",
      });
      hasSetValues.current = true;
    }
  }, [configNumberDetails, groupDetails]);

  useEffect(() => {
    if (phoneNumberDetails?.brandId || phoneNumberDetails?.campaignId) {
      const isCnpCampaign =
        !phoneNumberDetails?.brandId && phoneNumberDetails?.campaignId;
      setBrandTemp({
        brandId: phoneNumberDetails?.brandId,
        displayName: campaignsData?.records?.[0]?.brandName || "",
      });
      dlcFormik.setValues({
        ...dlcFormik.values,
        brandId: phoneNumberDetails?.brandId || "",
        campaignId: phoneNumberDetails?.campaignId || "",
        usecase: phoneNumberDetails?.usecase || "",
        description: phoneNumberDetails?.description || "",
        isCnpCampaign: isCnpCampaign,
      });
      setIsCnpCampaign(isCnpCampaign);
    }
  }, [phoneNumberDetails]);

  useEffect(() => {
    if (phoneNumberDetails?.subGroupId?.[0]) {
      setIsSubgrpCalling(true);
      dispatch(getSubgroupDetailsThunk(phoneNumberDetails?.subGroupId?.[0]));
    }
  }, [dispatch, phoneNumberDetails]);

  useEffect(() => {
    const fetchCampaignDetails = async () => {
      if (phoneNumberDetails?.campaignId && !campaignDetails) {
        try {
          const response = await fetchCampaignDetailsApi(
            phoneNumberDetails?.campaignId,
            phoneNumberDetails?.brandId
          );
          setCampaignDetails({ ...response });
        } catch (error) {
          console.error("Error fetching campaign details:", error);
        }
      }
    };

    fetchCampaignDetails();
  }, [phoneNumberDetails?.campaignId]);

  return (
    <div className="container-fluid">
      <BreadCrumb title="Configure Your Number" />
      {/* <Form onSubmit={formik.handleSubmit}> */}
      <Row>
        <div className="mb-1">
          <h5>
            Sub Group configuration for{" "}
            <span className="text-primary">
              {groupDetails?.records?.[0]?.group_name || "-"} (
              {groupDetails?.records?.[0]?.group_id || "-"})
            </span>
          </h5>
        </div>
        <Col lg={12} className="mb-4">
          <Row className="border bg-light-gray rounded-3 p-3">
            {formik.touched.groupId && formik.errors.groupId && (
              <Form.Control.Feedback
                type="invalid"
                className="d-block mt-0 mb-2"
              >
                {formik.errors.groupId}
              </Form.Control.Feedback>
            )}
            <Col lg={4}>
              <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
              {!isSubgrpCalling &&
                dlcFormik.values.brandId &&
                formik.values.subGroupId?.length < 1 && (
                  <p className="text-primary mb-1">
                    {dlcFormik.values.brandId || "Brand"} is not in a subgroup
                  </p>
                )}
              <AsyncPaginate
                isDisabled={!formik.values.groupId}
                menuPortalTarget={document.body}
                key={formik.values.groupId}
                isClearable={true}
                styles={styleObj(
                  formik.touched.subGroupId && formik.errors.subGroupId
                )}
                theme={customSelectTheme}
                value={subgroupTemp || {}}
                loadOptions={asyncSubgroupList(
                  removeEmptyAndNullValues({
                    groupId: groupDetails?.records?.[0]?.group_id,
                  }),
                  "subGroupId"
                )}
                getOptionValue={(option) => option?.sub_group_id}
                getOptionLabel={(option: any) =>
                  `${option?.sub_group_id} - ${option?.sub_group_name}`
                }
                onChange={(option) => {
                  if (option) {
                    setSubgroupTemp([option]);
                    formik.setFieldValue("subGroupId", [option]);
                  } else {
                    setSubgroupTemp([]);
                    formik.setFieldValue("subGroupId", []);
                  }
                  dlcFormik.setFieldValue("campaignId", "");
                  setBrandTemp({});
                  dlcFormik.setFieldValue("brandId", "");
                  dlcFormik.setFieldValue("isCnpCampaign", false);
                  dlcFormik.setTouched({
                    brandId: true,
                    campaignId: true,
                  });
                }}
                isSearchable={true}
                additional={{
                  page: 1,
                }}
              />
              {formik.touched.subGroupId && formik.errors.subGroupId && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {formik.errors.subGroupId}
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>
        </Col>

        <div className="mt-3 mb-1">
          <h5>10DLC Provisioning</h5>
        </div>
        <Col lg={12} className="mb-4">
          <DLC
            dlcFormik={dlcFormik}
            formik={formik}
            isSubmit={isSubmit}
            setIsSubgrpCalling={setIsSubgrpCalling}
            brandTemp={brandTemp}
            setBrandTemp={setBrandTemp}
          />
        </Col>

        <div className="mt-3 mb-1">
          <h5>Messaging Configuration</h5>
        </div>
        <Col lg={12} className="mb-4">
          <Row className="border bg-light-gray rounded-3 p-3">
            {/* <Col lg={4} className="mb-3">
              <Form.Label htmlFor="aMessageComesIn_Message">
                A message comes in
              </Form.Label>
              <Select
                name="aMessageComesIn_Message"
                styles={styleObj(
                  formik?.touched?.aMessageComesIn_Message &&
                    formik?.errors?.aMessageComesIn_Message
                )}
                theme={customSelectTheme}
                options={[
                  {
                    label: "Webhook",
                    value: "webhook",
                  },
                ]}
                onChange={(e) => {
                  formik.setFieldValue("aMessageComesIn_Message", e?.value);
                }}
                value={{
                  label: "Webhook",
                  value: formik.values.aMessageComesIn_Message,
                }}
              />
            </Col> */}








            <Col lg={8} className="mb-3">
              <Form.Label htmlFor="urlOne_Message"  className="d-flex align-items-center mb-0" >A message comes in URL
              <Tooltip
                  placement="right"
                  overlay="A webhook notification when a message is received."
                >
                  <i
                    className="ri-information-line text-secondary fs-5 ms-2"
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Form.Label>
              <Form.Control
                {...formik.getFieldProps("urlOne_Message")}
                className="form-control"
                placeholder="URL"
                data-choices
                data-choices-search-false
                id="urlOne_Message"
                style={{ width: "100%" }}
                isInvalid={
                  formik.touched.urlOne_Message && formik.errors.urlOne_Message
                }
              />
              {formik.touched.urlOne_Message &&
                formik.errors.urlOne_Message && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.urlOne_Message}
                  </Form.Control.Feedback>
                )}
            </Col>
            {/* <Col lg={4} className="mb-3">
              <Form.Label htmlFor="primaryHandlerFails_Message">
                Primary handler fails
              </Form.Label>
              <Select
                name="primaryHandlerFails_Message"
                styles={styleObj(
                  formik?.touched?.primaryHandlerFails_Message &&
                    formik?.errors?.primaryHandlerFails_Message
                )}
                theme={customSelectTheme}
                options={[
                  {
                    label: "Webhook",
                    value: "webhook",
                  },
                ]}
                onChange={(e) => {
                  formik.setFieldValue("primaryHandlerFails_Message", e?.value);
                }}
                value={{
                  label: "Webhook",
                  value: formik.values.primaryHandlerFails_Message,
                }}
              />
            </Col> */}
            <Col lg={8} className="mb-3">
              <Form.Label htmlFor="urlTwo_Message" className="d-flex align-items-center mb-0" >Backup URL
              <Tooltip
                  placement="right"
                  overlay="A backup webhook URL in the event the primary URL above fails."
                >
                  <i
                    className="ri-information-line text-secondary fs-5 ms-2"
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Form.Label>
              <Form.Control
                {...formik.getFieldProps("urlTwo_Message")}
                className="form-control"
                placeholder="URL"
                data-choices
                data-choices-search-false
                id="urlTwo_Message"
                style={{ width: "100%" }}
                isInvalid={
                  formik.touched.urlTwo_Message && formik.errors.urlTwo_Message
                }
              />
              {formik.touched.urlTwo_Message &&
                formik.errors.urlTwo_Message && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.urlTwo_Message}
                  </Form.Control.Feedback>
                )}
            </Col>
          </Row>
        </Col>

        <Col lg={12}>
          <div className="hstack gap-2">
            <Button
              className="btn btn-primary"
              style={{ background: "none", color: "#e51cfd" }}
              onClick={() => navigate("/mynumbers")}
            >
              Return to Active Numbers
            </Button>
            <Button
              disabled={isSubmit}
              onClick={() => {
                formik.handleSubmit(formik.values);
              }}
              className="btn btn-primary"
              type="buttom"
            >
              Save Configuration
            </Button>
          </div>
        </Col>
      </Row>
      {/* </Form> */}
    </div>
  );
};

export default withRouter(ConfigureNumbers);
