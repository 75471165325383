import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import Register from "pages/Authentication/Registration/Register";
import UserProfile from "pages/Authentication/user-profile";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import PasswordCreate from "pages/AuthenticationInner/PasswordCreate";
import VerifyEmail from "pages/Authentication/VerifyEmail";
import VerifyAuth from "pages/Authentication/VerifyAuth";
import ShortURL from "pages/Authentication/ShortURL";
import BrandsList from "pages/Brands/BrandsList";
import CreateBrand from "pages/Brands/CreateBrand";
import BrandDetails from "pages/Brands/BrandDetails";
// import Home from "pages/Home/Home";
import BuyNumbers from "pages/Numbers/BuyNumbers";
import Dashboard from "pages/Dashboard/Dashboard";
import Analytics from "pages/Analytics";
import ApiKeys from "pages/ApiKeys";
// import ConfigureNumbers from "pages/Home/ConfigureNumbers";
import MyNumbers from "pages/Home/MyNumbers";
import OwnNumberDetails from "pages/Home/OwnNumberDetails";
import ReleaseNumber from "pages/Home/ReleasedNumber";
// import VerifiedCallerList from "pages/PhoneNumbers/VerifiedCallerList";
import CampaignsList from "pages/Campaigns/CampaignsList";
import CreateCampaign from "pages/Campaigns/CreateCampaign";
import CampaignDetails from "pages/Campaigns/CampaignDetails";
import CampaignReviewDetails from "pages/Campaigns/CampaignReviewDetails";
import CreateRbmAgent from "pages/RBMAgent/CreateAgent";
// import BillingOverview from "pages/Billing/BillingOverview";
import AddFunds from "pages/Billing/AddFunds";
import AddPaymentMethod from "pages/Billing/AddPaymentMethod";
import PaymentHistory from "pages/Billing/PaymentHistory";
import SendSMS from "pages/Messaging/SendSMS";
import SendMMS from "pages/Messaging/SendMMS";
// import ListMessagingTamplets from "pages/Messaging/ListMessagingTamplets";
// import CreateMessageTemplate from "pages/Messaging/CreateMessageTemplate";
// import ActiveNumbers from "pages/Messaging/ActiveNumbers";
import Summary from "pages/UsageSummary/Summary";
import General from "pages/Authentication/Settings/General";
import Security from "pages/Authentication/Settings/Security";
import Users from "pages/Authentication/Settings/Users";
import NotificationEvents from "pages/Authentication/Settings/Notifications/NotificationEvents";
import Billing from "pages/Authentication/Settings/Billing";
import AccountPricing from "pages/Authentication/Settings/AccountPricing";
import UsageTransactions from "pages/Authentication/Settings/UsageTransactions";
import WebhooksList from "pages/Authentication/Settings/Webhooks/WebhooksList";
import WebhookDetails from "pages/Authentication/Settings/Webhooks/WebhookDetails";
import CreateNewWebhook from "pages/Authentication/Settings/CreateNewWebhook";
import PortNumbers from "pages/Numbers/PortNumbers";
import NewPortRequest from "pages/Numbers/NewPortRequest";
// import PhoneNumberLookup from "pages/Numbers/PhoneNumberLookup";
// import NumberLookupTabs from "pages/Numbers/NumberLookupTabs";
import settingdata from "Layout/LayoutSettingsData";
import MessageLogsList from "pages/Messaging/MessageLogsList";
import SmsAndMmsLogs from "pages/Messaging/SmsAndMmsLogs";
import SmsAndMmsLogsDetails from "pages/Messaging/SmsAndMmsLogsDetails";
import PortingStatusLogs from "pages/Numbers/PortingStatusLogs";
import AllGroups from "pages/Authentication/Settings/Groups/AllGroups";
import GroupDetails from "pages/Authentication/Settings/Groups/GroupDetails";
import CreateGroup from "pages/Authentication/Settings/Groups/CreateGroup";
import SubGroupsList from "pages/Authentication/Settings/SubGroups/SubGroupsList";
import SubGroupDetails from "pages/Authentication/Settings/SubGroups/SubGroupDetails";
import CreateSubGroup from "pages/Authentication/Settings/SubGroups/CreateSubGroup";
import NotificationsList from "pages/Notifications/NotificationsList";
import NotificationDetails from "pages/Notifications/NotificationDetails";
import HelpCenterList from "pages/HelpCenter/HelpCenterList";
import HelpCenterSubPages from "pages/HelpCenter/HelpCenterSubPages";
import HelpCenterDetails from "pages/HelpCenter/HelpCenterDetails";
import ApiDocs from "pages/apidocs/apidocs";
import MarketPlaceList from "pages/MarketPlace/MarketPlaceList";

const publicRoutes = [
  { path: "/login", name: "Login", component: <Login /> },
  { path: "/logout", name: "Logout", component: <Logout /> },
  { path: "/register", name: "Register", component: <Register /> },
  { path: "/apidoc", name: "apidoc", component: <ApiDocs /> },
  { path: "/api-docs", name: "api-docs", component: <ApiDocs /> },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: <ForgotPassword />,
  },
  {
    path: "/create-password",
    name: "PasswordCreate",
    component: <PasswordCreate />,
  },
  {
    path: "/verifyEmail",
    name: "VerifyEmail",
    component: <VerifyEmail />,
  },
  {
    path: "/verify-auth",
    name: "VerifyAuth",
    component: <VerifyAuth />,
  },
];

const authProtectedRoutes = [
  // other routes
  // { path: "/home", moduleKey: "", component: <Home /> },
  // {
  //   path: "/verified-caller-ids",
  //   moduleKey: "",
  //   component: <VerifiedCallerList />,
  // },
  // { path: "/add-funds", moduleKey: "", component: <AddFunds /> },
  // {
  //   path: "/message-template",
  //   moduleKey: "",
  //   component: <ListMessagingTamplets />,
  // },
  // {
  //   path: "/message-template/create",
  //   moduleKey: "",
  //   component: <CreateMessageTemplate />,
  // },
  // { path: "/message-template", moduleKey:"", component: <CreateMessageTemplate /> },
  // { path: "/PhoneNumberLookup", moduleKey:"", component: <PhoneNumberLookup /> },
  // { path: "/number-lookup", moduleKey:"", component: <NumberLookupTabs /> },
  // {
  //   path: "/message-template/:tamplete_id",
  //   moduleKey: "",
  //   component: <CreateMessageTemplate />,
  // },
  // { path: "/ConfigureNumbers", moduleKey:"", component: <ConfigureNumbers /> },
  // { path: "/billing-overview", moduleKey:"", component: <BillingOverview /> },
  // { path: "/activeNumber", moduleKey:"", component: <ActiveNumbers /> },

  // dashboard
  { path: "/", moduleKey: "dashboard", component: <Dashboard /> },
  { path: "/dashboard", moduleKey: "dashboard", component: <Dashboard /> },
  {
    path: "/dashboard/add-funds",
    moduleKey: "dashboard",
    component: <AddFunds />,
  },
  {
    path: "/dashboard/add-payment-method",
    moduleKey: "dashboard",
    component: <AddPaymentMethod />,
  },

  // settings
  { path: "/settings", moduleKey: "settings", component: <UserProfile /> },

  // settings.general
  {
    path: "/settings/general",
    isSubNavbar: true,
    subNavdata: settingdata,
    moduleKey: "settings.general",
    component: <General />,
  },

  // settings.security
  {
    path: "/settings/security",
    isSubNavbar: true,
    subNavdata: settingdata,
    moduleKey: "settings.security",
    component: <Security />,
  },

  // settings.users
  {
    path: "/settings/users",
    isSubNavbar: true,
    subNavdata: settingdata,
    moduleKey: "settings.users",
    component: <Users />,
  },

  // settings.notifications
  {
    path: "/settings/notification-events",
    isSubNavbar: true,
    subNavdata: settingdata,
    moduleKey: "settings.notifications",
    component: <NotificationEvents />,
  },
  {
    path: "/all-notifications",
    moduleKey: "settings.notifications",
    component: <NotificationsList />,
  },
  {
    path: "/all-notifications/:id",
    moduleKey: "settings.notifications",
    component: <NotificationDetails />,
  },

  // settings.groups
  {
    path: "/settings/groups",
    isSubNavbar: true,
    subNavdata: settingdata,
    moduleKey: "settings.groups",
    component: <AllGroups />,
  },
  {
    path: "/settings/groups/:groupId",
    isSubNavbar: true,
    subNavdata: settingdata,
    moduleKey: "settings.groups",
    component: <GroupDetails />,
  },
  {
    path: "/settings/groups/create",
    isSubNavbar: true,
    subNavdata: settingdata,
    moduleKey: "settings.groups",
    component: <CreateGroup />,
  },
  {
    path: "/settings/groups/:groupId/edit",
    isSubNavbar: true,
    subNavdata: settingdata,
    moduleKey: "settings.groups",
    component: <CreateGroup />,
  },
  {
    path: "/groups/:groupId/subgroups",
    // isSubNavbar: true,
    // subNavdata: settingdata,
    moduleKey: "groups",
    component: <SubGroupsList />,
  },
  {
    path: "/groups/:groupId/subgroups/:subGroupId/:subgroupnames",
    // isSubNavbar: true,
    // subNavdata: settingdata,
    moduleKey: "groups",
    component: <SubGroupDetails />,
  },
  {
    path: "/groups/:groupId/subgroups/create",
    // isSubNavbar: true,
    // subNavdata: settingdata,
    moduleKey: "groups",
    component: <CreateSubGroup />,
  },
  {
    path: "/groups/:groupId/subgroups/:subGroupId/edit",
    // isSubNavbar: true,
    // subNavdata: settingdata,
    moduleKey: "groups",
    component: <CreateSubGroup />,
  },

  // settings.billing
  {
    path: "/settings/billing",
    isSubNavbar: true,
    subNavdata: settingdata,
    moduleKey: "settings.billing",
    component: <Billing />,
  },
  {
    path: "/settings/billing/payment-history",
    isSubNavbar: true,
    subNavdata: settingdata,
    moduleKey: "settings.billing",
    component: <PaymentHistory />,
  },
  {
    path: "/settings/billing/usage-summary",
    isSubNavbar: true,
    subNavdata: settingdata,
    moduleKey: "settings.billing",
    component: <Summary />,
  },

  // settings.account-pricing
  {
    path: "/settings/account-pricing",
    isSubNavbar: true,
    subNavdata: settingdata,
    moduleKey: "settings.account-pricing",
    component: <AccountPricing />,
  },

  // settings.usage-transactions
  {
    path: "/settings/usage-transactions",
    isSubNavbar: true,
    subNavdata: settingdata,
    moduleKey: "settings.usage-transactions",
    component: <UsageTransactions />,
  },

  // api-keys
  { path: "/api-keys", moduleKey: "api-keys", component: <ApiKeys /> },

  // webhooks
  {
    path: "/webhooks",
    moduleKey: "webhooks",
    component: <WebhooksList />,
  },
  {
    path: "/webhooks-details/:webhookId",
    moduleKey: "webhooks",
    component: <WebhookDetails />,
  },
  {
    path: "/webhooks/new-webhook",
    moduleKey: "webhooks",
    component: <CreateNewWebhook />,
  },
  {
    path: "/webhooks/:webhookId",
    moduleKey: "webhooks",
    component: <CreateNewWebhook />,
  },

  // own-numbers
  { path: "/mynumbers", moduleKey: "own-numbers", component: <MyNumbers /> },
  {
    path: "/mynumbers/:phoneNumber/:sid",
    moduleKey: "own-numbers",
    component: <OwnNumberDetails />,
  },
  {
    path: "/mynumbers/:phoneNumber",
    moduleKey: "own-numbers",
    component: <OwnNumberDetails />,
  },

  // buy-a-number
  { path: "/buynumbers", moduleKey: "buy-a-number", component: <BuyNumbers /> },

  // release-number
  {
    path: "/release-number",
    moduleKey: "release-number",
    component: <ReleaseNumber />,
  },

  // port-a-number
  {
    path: "/port-numbers",
    moduleKey: "port-a-number",
    component: <PortNumbers />,
  },
  {
    path: "/port-numbers/new-porting-requests",
    moduleKey: "port-a-number",
    component: <NewPortRequest />,
  },
  {
    path: "/porting-status/:portingId",
    moduleKey: "port-a-number",
    component: <PortingStatusLogs />,
  },

  // analytics
  { path: "/analytics", moduleKey: "analytics", component: <Analytics /> },

  // message-logs
  {
    path: "/message-logs",
    moduleKey: "message-logs",
    component: <MessageLogsList />,
  },
  {
    path: "/sms-logs",
    moduleKey: "message-logs",
    component: <SmsAndMmsLogs />,
  },
  {
    path: "/mms-logs",
    moduleKey: "message-logs",
    component: <SmsAndMmsLogs />,
  },
  {
    path: "/sms-logs-details/:s_id",
    moduleKey: "message-logs",
    component: <SmsAndMmsLogsDetails />,
  },
  {
    path: "/mms-logs-details/:s_id",
    moduleKey: "message-logs",
    component: <SmsAndMmsLogsDetails />,
  },
  {
    path: "/programmable-sms-logs-details/:s_id",
    moduleKey: "message-logs",
    component: <SmsAndMmsLogsDetails />,
  },
  {
    path: "/number-logs-details/:s_id",
    moduleKey: "message-logs",
    component: <SmsAndMmsLogsDetails />,
  },

  // brands
  { path: "/brands", moduleKey: "brands", component: <BrandsList /> },
  { path: "/brands/create", moduleKey: "brands", component: <CreateBrand /> },
  {
    path: "/brands/edit/:brand_id",
    moduleKey: "brands",
    component: <CreateBrand />,
  },
  { path: "/brands/:id", moduleKey: "brands", component: <BrandDetails /> },

  // campaigns-child
  {
    path: "/campaigns",
    moduleKey: "campaigns-child",
    component: <CampaignsList />,
  },
  {
    path: "/campaigns/create",
    moduleKey: "campaigns-child",
    component: <CreateCampaign />,
  },
  {
    path: "/campaigns/:id",
    moduleKey: "campaigns-child",
    component: <CampaignDetails />,
  },
  {
    path: "/campaigns-partner/:id",
    moduleKey: "campaigns-child",
    component: <CampaignDetails />,
  },
  {
    path: "/campaigns-review/:id",
    moduleKey: "campaigns-child",
    component: <CampaignReviewDetails />,
  },
  {
    path: "/campaigns-rejected/:id",
    moduleKey: "campaigns-child",
    component: <CampaignReviewDetails />,
  },

  // rbm-agent
  {
    path: "/rbm-agent/create",
    moduleKey: "rbm-agent",
    component: <CreateRbmAgent />,
  },

  // send-sms
  { path: "/send-sms", moduleKey: "send-sms", component: <SendSMS /> },

  // send-mms
  { path: "/send-mms", moduleKey: "send-mms", component: <SendMMS /> },

  // help-center
  {
    path: "/help-center",
    moduleKey: "help-center",
    component: <HelpCenterList />,
  },
  {
    path: "/help-center/:pageId",
    moduleKey: "help-center",
    component: <HelpCenterSubPages />,
  },
  {
    path: "/help-center/:pageId/:subpageId",
    moduleKey: "help-center",
    component: <HelpCenterDetails />,
  },

  // marketplace
  {
    path: "/marketplace",
    moduleKey: "marketplace",
    component: <MarketPlaceList />,
  },

  // short-url
  {
    path: "/short-url",
    moduleKey: "short-url",
    component: <ShortURL />,
  },
];

export { authProtectedRoutes, publicRoutes };
