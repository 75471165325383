import React, { useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Datatable from "../../Common/Datatable";
import { capitalizeString, dateFormat } from "helpers/common";
import { getMessageLogDetailsThunk, getMmsLogDetailsThunk } from "slices/thunk";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import Loader from "assets/images/spinner-dark.svg";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";

enum LocationType {
  SMS_LOGS_DETAIL_PAGE = "sms-logs-details",
  MMS_LOGS_DETAIL_PAGE = "mms-logs-details",
}

const SmsAndMmsLogsDetails = (props: any) => {
  document.title = "Signal House Portal Messaging Details";
  const { s_id } = useParams();
  const dispatch = useDispatch<any>();
  const CURRENT_LOCATION = props.router?.location.pathname?.split("/")[1];
  const detailData = useSelector((state: any) =>
    CURRENT_LOCATION === LocationType.MMS_LOGS_DETAIL_PAGE
      ? state.Messages?.MmsLogDetails
      : state.Messages?.MessageLogDetails
  );

  const selectProfile = createSelector(
    (state: any) => state.Messages,
    (messages) => ({
      loading: messages.loading,
      error: messages.error,
      MessageLogDetails: messages.MessageLogDetails,
    })
  );
  const { loading } = useSelector(selectProfile);

  //Table columns
  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Steps</span>,
      selector: (cell: { status: string; brandIdentityStatus: string }) => {
        if (!cell.status) {
          return (
            <span className="badge bg-dark-subtle text-body fs-sm">
              {capitalizeString(cell.brandIdentityStatus)}
            </span>
          );
        }
        switch (cell.status.toLowerCase()) {
          case "queued":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-info border border-info text-info"
              >
                {capitalizeString(cell.status)}
              </span>
            );
          case "created":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge text-start fs-sm"
                style={{
                  background: " #CDD5DA ",
                  color: "#888e91",
                }}
              >
                {capitalizeString(cell.status)}
              </span>
            );
          case "enqueued":
            return (
              <span
                className="badge text-start fs-sm"
                style={{
                  background: " #b6bdc2",
                  color: "#5b5e61",
                }}
              >
                {capitalizeString(cell.status)}
              </span>
            );
          case "dequeued":
            return (
              <span
                className="badge text-start fs-sm"
                style={{
                  background: " #888e91",
                  color: "#2d2f30",
                }}
              >
                {capitalizeString(cell.status)}
              </span>
            );
          case "delivered":
            return (
              <span className="badge bg-secondary-subtle text-secondary text-start fs-sm">
                {capitalizeString(cell.status)}
              </span>
            );
          case "mo_message_received":
            return (
              <span className="badge bg-secondary-subtle text-secondary text-start fs-sm">
                {capitalizeString(cell.status.replace("mo_message_", ""))}
              </span>
            );
          case "sent":
            return (
              <span
                className="badge text-start fs-sm"
                style={{
                  background: " #000000",
                  color: "white",
                }}
              >
                {capitalizeString(cell.status)}
              </span>
            );
          case "failed":
            return (
              <span className="badge bg-primary-subtle text-primary text-start fs-sm">
                {capitalizeString(cell.status)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-primary-subtle text-primary text-start fs-sm"
              >
                {capitalizeString(cell.status)}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const statusA =
          rowA.status?.toLowerCase() ||
          rowA.brandIdentityStatus?.toLowerCase() ||
          "";
        const statusB =
          rowB.status?.toLowerCase() ||
          rowB.brandIdentityStatus?.toLowerCase() ||
          "";
        return statusA.localeCompare(statusB);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Timestamp</span>,
      minWidth: "220px",
      selector: (row: { date: string }) => row.date,
      cell: (row: { date: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.date, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const dateA = new Date(rowA.date).getTime();
        const dateB = new Date(rowB.date).getTime();
        return dateA - dateB;
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Elapsed Time</span>,
      minWidth: "110px",
      selector: (row: { elapsedTime: string }) => row.elapsedTime ?? "-",
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const timeA = rowA.elapsedTime || "0";
        const timeB = rowB.elapsedTime || "0";
        return parseFloat(timeA) - parseFloat(timeB);
      },
    },
  ];

  //API call with / signalHouseSID
  useEffect(() => {
    dispatch(
      CURRENT_LOCATION === LocationType.MMS_LOGS_DETAIL_PAGE
        ? getMmsLogDetailsThunk({ signalHouseSID: s_id })
        : getMessageLogDetailsThunk({ signalHouseSID: s_id })
    );
  }, [CURRENT_LOCATION]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Message Details"
            isBack={true}
            backClick={() => props.router?.navigate(-1)}
          />
          <div className="position-relative wrapper">
            {loading ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <>
                <Card>
                  <Card.Body>
                    <div className="mb-1">
                      <h5>Properties</h5>
                    </div>
                    <Col lg={12} className="my-3">
                      <Row className="border bg-light-gray  rounded-3 p-3">
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Signalhouse Message SID:</h6>
                          <p className="mb-0">
                            {detailData?.messageLog?.SMSData?.[0]
                              ?.signalHouseSID ?? "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Direction:</h6>
                          <p className="mb-0">
                            {detailData?.messageLog?.SMSData?.[0]?.direction ??
                              "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Segments:</h6>
                          <p className="mb-0">
                            {detailData?.messageLog?.SMSData?.[0]?.segments ??
                              "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Time Stamp:</h6>
                          <p className="mb-0">
                            {dateFormat(
                              detailData?.messageLog?.SMSData?.[0]?.timeStamp,
                              "MM/DD/YYYY, hh:mm:ss A"
                            )}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">From:</h6>
                          <p className="mb-0">
                            {detailData?.messageLog?.SMSData?.[0]
                              ?.sendersPhoneNumber ?? "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">To:</h6>
                          <p className="mb-0">
                            {detailData?.messageLog?.SMSData?.[0]
                              ?.recieversPhoneNumber ?? "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Cost:</h6>
                          <p className="mb-0">
                            {detailData?.messageLog?.SMSData?.[0]?.price !==
                              null &&
                            detailData?.messageLog?.SMSData?.[0]?.price !==
                              undefined
                              ? `$${detailData?.messageLog?.SMSData?.[0]?.price} USD`
                              : "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Status:</h6>
                          <p className="mb-0">
                            {detailData?.messageLog?.SMSData?.[0]?.status ??
                              "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Status Description:</h6>
                          <p className="mb-0">
                            {typeof detailData?.messageLog?.SMSData?.[0]
                              ?.StatusDescription === "string"
                              ? detailData?.messageLog?.SMSData?.[0]
                                  ?.StatusDescription
                              : typeof detailData?.messageLog?.SMSData?.[0]
                                  ?.StatusDescription === "object" &&
                                detailData?.messageLog?.SMSData?.[0]
                                  ?.StatusDescription.message
                              ? detailData?.messageLog?.SMSData?.[0]
                                  ?.StatusDescription.message
                              : "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3 mb-md-0">
                          <h6 className="mb-1">Callback URL:</h6>
                          <p className="mb-0">
                            {detailData?.messageLog?.SMSData?.[0]
                              ?.statusCallBackUrl ?? "-"}
                          </p>
                        </Col>
                        <Col md={6} className="">
                          <h6 className="mb-1">Body:</h6>
                          <p className="mb-0">
                            {detailData?.messageLog?.SMSData?.[0]
                              ?.messageBody ?? "-"}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <div className="mb-1">
                      <h5>Delivery Steps</h5>
                    </div>
                    <Col lg={12} className="my-3">
                      <Row className="border bg-light-gray rounded-3 p-3">
                        <Col md={12} className="">
                          <Datatable
                            data={detailData?.messageLog?.messageSteps || []}
                            columns={columns}
                            pagination={false}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Card.Body>
                </Card>
              </>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SmsAndMmsLogsDetails);
