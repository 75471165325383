import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Row,
  Col,
  Form,
  Spinner,
  Container,
  Dropdown,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getPersonalMessagesLogsThunk } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "Common/BreadCrumb";
import {
  asyncBrandList,
  asyncCampaignList,
  asyncSubgroupList,
  dateRangeArr,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { customSelectTheme } from "helpers/common";
import withRouter from "Common/withRouter";
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment";
import Select from "react-select";
import { GroupSelect } from "Common/filter/group-select";
import { BrandSelect } from "Common/filter/brand-select";
import MessageTable from "./MessageTable";

const phoneNumberWithPlus = /^\+\w+/;

const SmsAndMmsLogs = (props: any) => {
  document.title = "Signal House Portal Messaging Logs";

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeDir, setActiveDir] = useState("");
  const [dateRangeTemp, setDateRangeTemp] = useState<any>(null);

  const customSort = (rows: any, selector: any, direction: string) => {
    return rows.sort((rowA: any, rowB: any) => {
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const selectProfile = createSelector(
    (state: any) => state.Messages,
    (state: any) => state.Groups,
    (messages, groups) => ({
      loading2: messages.loading,
      groupDetails: groups?.AllGroups,
      messageLogs: messages.MessageLogs,
    })
  );

  const { loading2, groupDetails, messageLogs } = useSelector(selectProfile);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      signalHouseSID: "",
      from: props?.phoneNumber || "",
      to: "",
      subGroupId: [],
      brandId: [],
      campaignId: [],
      createdAt: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      const temp = {
        ...values,
        subGroupId: values.subGroupId.length > 0 ? values.subGroupId : "",
        brandId:
          values.brandId.length > 0
            ? values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          values.campaignId.length > 0
            ? values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        endDate: values.endDate
          ? moment(values.endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
      };
      setOpen(false);
      handleGetData(1, rowsPerPage, removeEmptyAndNullValues(temp));
    },
  });

  useEffect(() => {
    handleGetData(
      1,
      rowsPerPage,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  }, [activeDir]);

  const handleGetData = async (page: number, perPage: number, values?: any) => {
    setLoading(true);

    const dir = activeDir || "All";
    const formattedFrom = values.from
      ? phoneNumberWithPlus.test(values.from)
        ? `%2B${values.from.split("+")[1]}`
        : values.from
      : "";
    const formattedTo = values.to
      ? phoneNumberWithPlus.test(values.to)
        ? `%2B${values.to.split("+")[1]}`
        : values.to
      : "";

    const allParams = {
      ...values,
      direction: dir === "All" ? "" : dir.toLowerCase(),
      from: formattedFrom,
      to: formattedTo,
      createdAt: "",
    };

    try {
      await dispatch(
        getPersonalMessagesLogsThunk(
          { page: page, recordsPerPage: perPage },
          props.phoneNumber,
          removeEmptyAndNullValues(allParams)
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (row: { signalHouseSID: string }) => {
    navigate(`/programmable-sms-logs-details/${row.signalHouseSID}`);
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(
      page,
      rowsPerPage,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        endDate: validation.values.endDate
          ? moment(validation.values.endDate, "MM-DD-YYYY")
              .endOf("day")
              .toISOString()
          : "",
      })
    );
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <BreadCrumb
            title="Programmable Messaging Logs"
            isFilter={true}
            filterButton={
              <>
                <Dropdown className="card-header-dropdown flex-shrink-0 cursor-pointer">
                  <Dropdown.Toggle
                    as="a"
                    className="text-reset arrow-none mb-0"
                  >
                    <h5 className="mb-0">
                      {activeDir || "All"}{" "}
                      <i className="mdi mdi-chevron-down ms-1" />
                    </h5>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-end">
                    {["All", "Inbound", "Outbound"]?.map((dt, i) => (
                      <li key={i}>
                        <Dropdown.Item onClick={() => setActiveDir(dt)}>
                          {dt}
                        </Dropdown.Item>
                      </li>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  align="end"
                  className="filterDropDown2"
                  show={open}
                  onToggle={() => {
                    if (!datePickerModal) {
                      setOpen(!open);
                    }
                  }}
                >
                  <Dropdown.Toggle variant="secondary" className="ms-2">
                    <i className="bx bx-filter-alt me-1"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-md p-4">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                      }}
                    >
                      <div className="mb-3">
                        <Form.Label htmlFor="signalHouseSID">Search</Form.Label>
                        <div className="d-flex">
                          <input
                            type="text"
                            placeholder="Search by Message SID"
                            className="form-control"
                            {...validation.getFieldProps("signalHouseSID")}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <Button className="btn btn-primary mb-3" type="submit">
                          Search
                        </Button>
                      </div>
                      <Row>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="from">From</Form.Label>
                          <input
                            type="number"
                            placeholder="From"
                            className="form-control"
                            {...validation.getFieldProps("from")}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="to">To</Form.Label>
                          <input
                            type="number"
                            placeholder="To"
                            className="form-control"
                            {...validation.getFieldProps("to")}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="subGroupId">
                            Sub Group
                          </Form.Label>
                          <GroupSelect
                            isDisabled={
                              !groupDetails?.records?.[0]?.group_id || ""
                            }
                            key={groupDetails?.records?.[0]?.group_id || ""}
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            styles={styleObj(
                              validation.touched?.subGroupId &&
                                validation.errors?.subGroupId
                            )}
                            theme={customSelectTheme}
                            value={
                              validation.values.subGroupId?.length > 0
                                ? validation.values.subGroupId.map(
                                    (dt: string) => ({
                                      sub_group_id: dt,
                                    })
                                  )
                                : null
                            }
                            loadOptions={asyncSubgroupList(
                              removeEmptyAndNullValues({
                                groupId:
                                  groupDetails?.records?.[0]?.group_id || "",
                              }),
                              "subGroupId"
                            )}
                            getOptionValue={(option: any) =>
                              option?.sub_group_id
                            }
                            getOptionLabel={(option: any) =>
                              option?.sub_group_name +
                              " - " +
                              option?.sub_group_id
                            }
                            onChange={(option: any) => {
                              if (option) {
                                validation.setFieldValue(
                                  "subGroupId",
                                  option.map((dt: any) => dt.sub_group_id)
                                );
                                validation.setFieldValue("brandId", []);
                                validation.setFieldValue("campaignId", []);
                              } else {
                                validation.setFieldValue("subGroupId", []);
                              }
                            }}
                            additional={{
                              page: 1,
                            }}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="brandId">Brand</Form.Label>
                          <BrandSelect
                            key={
                              validation.values.subGroupId.toString() ||
                              groupDetails?.records?.[0]?.group_id ||
                              "brandId"
                            }
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            styles={styleObj(
                              validation.touched?.brandId &&
                                validation.errors?.brandId
                            )}
                            theme={customSelectTheme}
                            value={
                              validation.values.brandId?.length > 0
                                ? validation.values.brandId
                                : null
                            }
                            loadOptions={asyncBrandList(
                              removeEmptyAndNullValues({
                                groupId:
                                  groupDetails?.records?.[0]?.group_id || "",
                                subGroupId:
                                  validation.values.subGroupId.length > 0
                                    ? validation.values.subGroupId
                                    : "",
                              }),
                              "brandId"
                            )}
                            getOptionValue={(option: any) => option?.brandId}
                            getOptionLabel={(option: any) =>
                              option?.displayName + " - " + option?.brandId
                            }
                            onChange={(option: any) => {
                              if (option) {
                                validation.setFieldValue(
                                  "subGroupId",
                                  option.reduce(
                                    (acc: string[], dt: any) => {
                                      if (
                                        dt?.subGroupId &&
                                        Array.isArray(dt.subGroupId)
                                      ) {
                                        dt.subGroupId.forEach(
                                          (subGroup: string) => {
                                            if (
                                              !acc.includes(subGroup) &&
                                              !validation.values.subGroupId.includes(
                                                subGroup
                                              )
                                            ) {
                                              acc.push(subGroup);
                                            }
                                          }
                                        );
                                      }
                                      return acc;
                                    },
                                    [...validation.values.subGroupId]
                                  )
                                );
                                validation.setFieldValue(
                                  "brandId",
                                  option.map((dt: any) => ({
                                    subGroupId: dt?.subGroupId || [],
                                    brandId: dt?.brandId || "",
                                  }))
                                );
                                validation.setFieldValue("campaignId", []);
                              } else {
                                validation.setFieldValue("brandId", []);
                              }
                            }}
                            additional={{
                              page: 1,
                            }}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                          <AsyncPaginate
                            key={
                              validation.values.brandId
                                ?.map((dt: any) => dt.brandId)
                                .toString() ||
                              validation.values.subGroupId.toString() ||
                              groupDetails?.records?.[0]?.group_id ||
                              "campaignId"
                            }
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            styles={styleObj(
                              validation.touched?.campaignId &&
                                validation.errors?.campaignId
                            )}
                            theme={customSelectTheme}
                            value={
                              validation.values.campaignId?.length > 0
                                ? validation.values.campaignId
                                : null
                            }
                            loadOptions={asyncCampaignList(
                              removeEmptyAndNullValues({
                                groupId:
                                  groupDetails?.records?.[0]?.group_id || "",
                                subGroupId:
                                  validation.values.subGroupId.length > 0
                                    ? validation.values.subGroupId
                                    : "",
                                brandId:
                                  validation.values.brandId.length > 0
                                    ? validation.values.brandId?.map(
                                        (dt: any) => dt.brandId
                                      )
                                    : "",
                              }),
                              "campaignId"
                            )}
                            getOptionValue={(option: any) => option?.campaignId}
                            getOptionLabel={(option: any) => option?.campaignId}
                            onChange={(option: any) => {
                              if (option) {
                                validation.setFieldValue(
                                  "subGroupId",
                                  option.reduce(
                                    (acc: string[], dt: any) => {
                                      if (
                                        dt?.subGroupId &&
                                        Array.isArray(dt.subGroupId)
                                      ) {
                                        dt.subGroupId.forEach(
                                          (subGroup: string) => {
                                            if (
                                              !acc.includes(subGroup) &&
                                              !validation.values.subGroupId.includes(
                                                subGroup
                                              )
                                            ) {
                                              acc.push(subGroup);
                                            }
                                          }
                                        );
                                      }
                                      return acc;
                                    },
                                    [...validation.values.subGroupId]
                                  )
                                );
                                validation.setFieldValue(
                                  "brandId",
                                  option.reduce(
                                    (acc: any[], dt: any) => {
                                      const existingItem =
                                        validation.values.brandId.find(
                                          (item: any) =>
                                            item.brandId === dt.brandId &&
                                            JSON.stringify(item.subGroupId) ===
                                              JSON.stringify(dt.subGroupId)
                                        );
                                      if (!existingItem) {
                                        acc.push({
                                          subGroupId: dt?.subGroupId || [],
                                          brandId: dt?.brandId || "",
                                        });
                                      }
                                      return acc;
                                    },
                                    [...validation.values.brandId]
                                  )
                                );
                                validation.setFieldValue(
                                  "campaignId",
                                  option.map((dt: any) => ({
                                    subGroupId: dt?.subGroupId || [],
                                    brandId: dt?.brandId || "",
                                    campaignId: dt?.campaignId || "",
                                  }))
                                );
                              } else {
                                validation.setFieldValue("campaignId", []);
                              }
                            }}
                            additional={{
                              page: 1,
                            }}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="dateRange">
                            Date Range
                          </Form.Label>
                          <Select
                            isClearable={true}
                            styles={styleObj(false)}
                            theme={customSelectTheme}
                            options={dateRangeArr}
                            onChange={(e: any) => {
                              setDateRangeTemp(e);
                              let startDate = "";
                              let endDate = moment()
                                .endOf("day")
                                .format("MM-DD-YYYY");

                              if (e?.value === "24 hours") {
                                startDate = moment()
                                  .subtract(1, "days")
                                  .startOf("day")
                                  .toISOString();
                              } else if (e?.value === "7 days") {
                                startDate = moment()
                                  .subtract(7, "days")
                                  .startOf("day")
                                  .toISOString();
                              } else if (e?.value === "30 days") {
                                startDate = moment()
                                  .subtract(30, "days")
                                  .startOf("day")
                                  .toISOString();
                              } else if (e?.value === "120 days") {
                                startDate = moment()
                                  .subtract(120, "days")
                                  .startOf("day")
                                  .toISOString();
                              } else {
                                endDate = "";
                              }

                              validation.setFieldValue("createdAt", "");
                              validation.setFieldValue("startDate", startDate);
                              validation.setFieldValue("endDate", endDate);
                            }}
                            value={dateRangeTemp}
                          />
                        </Col>
                        {dateRangeTemp?.value === "Custom" ? (
                          <Col sm={6} className="mb-3">
                            <Form.Label htmlFor="createdAt">
                              Select Range
                            </Form.Label>
                            <Flatpickr
                              className="form-control"
                              name="createdAt"
                              placeholder="Select Range"
                              options={{
                                enableTime: false,
                                onOpen: () => {
                                  setDatePickerModal(true);
                                },
                                onClose: () => {
                                  setDatePickerModal(false);
                                },
                                altFormat: "F j, Y",
                                dateFormat: "m-d-Y",
                                mode: "range",
                                onChange: (
                                  value: any,
                                  dateStr: string,
                                  instance: any
                                ) => {
                                  validation.setFieldValue(
                                    "createdAt",
                                    dateStr || ""
                                  );
                                  value?.[0] &&
                                    validation.setFieldValue(
                                      "startDate",
                                      moment(value?.[0]).format("MM-DD-YYYY")
                                    );
                                  value?.[1] &&
                                    validation.setFieldValue(
                                      "endDate",
                                      moment(value?.[1]).format("MM-DD-YYYY")
                                    );
                                },
                              }}
                              value={validation.values.createdAt || ""}
                            />
                          </Col>
                        ) : null}
                      </Row>
                      <div className="d-flex align-items-center justify-content-end">
                        <Button
                          className="btn btn-secondary me-2"
                          type="button"
                          disabled={loading2}
                          onClick={() => {
                            handleGetData(
                              currPage,
                              rowsPerPage,
                              removeEmptyAndNullValues({
                                ...validation.initialValues,
                                subGroupId: "",
                                brandId: "",
                                campaignId: "",
                              })
                            );
                            validation.resetForm();
                            setDatePickerModal(false);
                            setDateRangeTemp({
                              label: "",
                              value: "",
                            });
                          }}
                        >
                          Clear
                        </Button>
                        <Button
                          className="btn btn-primary"
                          type="submit"
                          disabled={loading2}
                        >
                          {loading2 && <Spinner size="sm" animation="border" />}{" "}
                          Apply
                        </Button>
                      </div>
                    </Form>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            }
          />

          <div className="listing-table4">
            {loading ? (
              <div className="position-relative" style={{ height: 200 }}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                />
              </div>
            ) : (
              <MessageTable
                data={
                  Array.isArray(messageLogs?.records)
                    ? messageLogs?.records
                    : []
                }
                handleRowClick={handleRowClick}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                currPage={currPage}
                rowsPerPage={rowsPerPage}
                totalRecords={messageLogs?.totalRecords}
                customSort={customSort}
              />
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(SmsAndMmsLogs);
