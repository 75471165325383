import {
  verticalListApi,
  stockExchangeListApi,
  countryListApi,
  stateListApi,
  brandAppealCategoryListApi,
  entityTypeListApi,
  brandRelationshipListApi,
  usecaseListApi,
  connectivityPartnersListApi,
  BrandSuspendedMNOsApi,
  areaCodeApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  verticalDataSuccessful,
  verticalDataFailed,
  stockExchangeDataSuccessful,
  stockExchangeDataFailed,
  countryDataSuccessful,
  countryDataFailed,
  stateDataSuccessful,
  stateDataFailed,
  brandAppealCategoryDataSuccessful,
  brandAppealCategoryDataFailed,
  entityTypeDataSuccessful,
  entityTypeDataFailed,
  brandRelationshipDataSuccessful,
  brandRelationshipDataFailed,
  usecaseDataSuccessful,
  usecaseDataFailed,
  connectivityPartnersDataSuccessful,
  connectivityPartnersDataFailed,
  BrandSuspendedSuccessful,
  BrandSuspendedFailed,
  areaCodeSuccessful,
  areaCodeDataFailed,
  resetAreaCodeSuccessful
} from "./reducer";
import { toast } from "react-toastify";

export const getVerticalListThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await verticalListApi();

    if (data?.code || data?.message) {
      dispatch(verticalDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(verticalDataSuccessful(data));
    }
  } catch (error: any) {
    dispatch(verticalDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};



export const getStockExchangeListThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await stockExchangeListApi();

    if (data?.code || data?.message) {
      dispatch(stockExchangeDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(stockExchangeDataSuccessful(data));
    }
  } catch (error: any) {
    dispatch(stockExchangeDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const getCountryListThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await countryListApi();

    if (data?.code || data?.message) {
      dispatch(countryDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(countryDataSuccessful(data));
    }
  } catch (error: any) {
    dispatch(countryDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const getStateListThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await stateListApi();

    if (data?.code || data?.message) {
      dispatch(stateDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(stateDataSuccessful(data));
    }
  } catch (error: any) {
    dispatch(stateDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const getBrandAppealCategoryListThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await brandAppealCategoryListApi();

    if (data?.code || data?.message) {
      dispatch(brandAppealCategoryDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(brandAppealCategoryDataSuccessful(data));
    }
  } catch (error: any) {
    dispatch(brandAppealCategoryDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const getEntityTypeListThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await entityTypeListApi();

    if (data?.code || data?.message) {
      dispatch(entityTypeDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(entityTypeDataSuccessful(data));
    }
  } catch (error: any) {
    dispatch(entityTypeDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const getBrandRelationshipListThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await brandRelationshipListApi();

    if (data?.code || data?.message) {
      dispatch(brandRelationshipDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(brandRelationshipDataSuccessful(data));
    }
  } catch (error: any) {
    dispatch(brandRelationshipDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const getUsecaseListThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await usecaseListApi();

    if (data?.code || data?.message) {
      dispatch(usecaseDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(usecaseDataSuccessful(data));
    }
  } catch (error: any) {
    dispatch(usecaseDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const getConnectivityPartnersListThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await connectivityPartnersListApi();

    if (data?.code || data?.message) {
      dispatch(connectivityPartnersDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(connectivityPartnersDataSuccessful(data));
    }
  } catch (error: any) {
    dispatch(connectivityPartnersDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const BrandSuspendedMnoThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await BrandSuspendedMNOsApi();

    if (data?.code || data?.message) {
      dispatch(BrandSuspendedFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(BrandSuspendedSuccessful(data));
    }
  } catch (error: any) {
    dispatch(BrandSuspendedFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const getAreaCodeThunk = (payload: { state: string; city: string }) => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await areaCodeApi(payload);

    if (data?.code || data?.message) {
      dispatch(areaCodeDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(areaCodeSuccessful(data));
    }
  } catch (error: any) {
    dispatch(areaCodeDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};
export const resetAreaCodeThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());
  dispatch(resetAreaCodeSuccessful());
};