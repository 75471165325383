import BreadCrumb from "Common/BreadCrumb";
import Datatable from "Common/Datatable";
import withRouter from "Common/withRouter";
import { useFormik } from "formik";
import {
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { createSelector } from "reselect";
import {
  fetchNumbersListThunk,
  openModal,
  purchaseNumberThunk,
  updateUserStepsThunk,
  getAreaCodeThunk,
  resetAreaCodeThunk,
} from "slices/thunk";
import { City, Country, State } from "country-state-city";
import { submitLoadingSubject } from "Common/modals/Modal";
import Loader from "assets/images/spinner-dark.svg";
import Tooltip from "rc-tooltip";
import { useSearchParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import * as Yup from "yup";

const capabilitiesOptions = [
  { label: "All", value: "all" },
  { label: "SMS", value: "sms" },
  { label: "MMS", value: "mms" },
];

const tfpOpt = ["800", "822", "833", "844", "855", "866", "877", "888"];

const BuyNumbers: React.FC = (props: any) => {
  document.title = "Signal House Portal Buy Numbers";
  const dispatch: any = useDispatch();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const subgroupnames = searchParams.get("subgroupnames");

  const [open, setOpen] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allCountry, setAllCountry] = useState<any>([]);
  const [allState, setAllState] = useState<any>([]);
  const [allCity, setAllCity] = useState<any>(City.getCitiesOfCountry("US"));
  const [isChecked, setIsChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const numberData = createSelector(
    (state: any) => state.Numbers,
    (state: any) => state.AuthUser,
    (state: any) => state.Enums,
    (numbers, authUser, enums) => ({
      loading: numbers.loading,
      dataObj: numbers.dataObj,
      error: numbers.error,
      stepsData: authUser.stepsData,
      user: authUser.authUser,
      getAreaCode: enums?.areaCode,
    })
  );

  const { loading, dataObj, stepsData, user, getAreaCode } =
    useSelector(numberData);
  const columns = [
    {
      name: (
        <Form.Check
          type="checkbox"
          checked={
            dataObj?.records?.length > 0 &&
            selectedRows.length === dataObj?.records?.length
          }
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRows(
                dataObj?.records?.map((row: any) => row.phoneNumber) || []
              );
            } else {
              setSelectedRows([]);
            }
          }}
        />
      ),
      width: "50px",
      cell: (row: any) => (
        <Form.Check
          type="checkbox"
          checked={selectedRows.includes(row.phoneNumber)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRows([...selectedRows, row.phoneNumber]);
            } else {
              setSelectedRows(
                selectedRows.filter((id) => id !== row.phoneNumber)
              );
            }
          }}
        />
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Number</span>,
      minWidth: "130px",
      selector: (row: { phoneNumber: number | string }) =>
        row?.phoneNumber || "",
      cell: (row: { phoneNumber: number | string }) => (
        <span className="text-primary">{row?.phoneNumber || "-"}</span>
      ),
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const a = String(rowA.phoneNumber || "");
        const b = String(rowB.phoneNumber || "");
        return a.localeCompare(b);
      },
    },

    {
      name: <span className="font-weight-bold fs-sm">Features</span>,
      minWidth: "150px",
      selector: (row: {
        capabilities?: {
          voice?: boolean;
          sms?: boolean;
          mms?: boolean;
          fax?: boolean;
        };
      }) => {
        const caps = row?.capabilities || {};
        return Object.keys(caps)
          .filter((key) => caps[key as keyof typeof caps])
          .join(",");
      },
      cell: (row: {
        capabilities?: {
          voice?: boolean;
          sms?: boolean;
          mms?: boolean;
          fax?: boolean;
        };
      }) => {
        return (
          <div
            className="d-flex flex-row align-items-center flex-nowrap align-content-center"
            style={{
              gap: "20px",
            }}
          >
            {row?.capabilities?.voice && (
              <Tooltip placement="bottom" overlay={"Voice"}>
                <i className="bi bi-telephone fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.sms && (
              <Tooltip placement="bottom" overlay={"SMS"}>
                <i className="bi bi-chat-left-text fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.mms && (
              <Tooltip placement="bottom" overlay={"MMS"}>
                <i className="bi bi-file-image fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.fax && (
              <Tooltip placement="bottom" overlay={"Fax"}>
                <i className="bi bi-file-earmark-text fs-lg"></i>
              </Tooltip>
            )}
          </div>
        );
      },
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const capA = rowA.capabilities || {};
        const capB = rowB.capabilities || {};
        const featuresA = Object.keys(capA)
          .filter((key) => capA[key])
          .join("");
        const featuresB = Object.keys(capB)
          .filter((key) => capB[key])
          .join("");
        return featuresA.localeCompare(featuresB);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Monthly Fee</span>,
      selector: (row: { monthlyCharges?: number }) => row?.monthlyCharges || 0,
      cell: (row: { monthlyCharges?: number }) =>
        `$${row?.monthlyCharges ? (+row?.monthlyCharges)?.toFixed(2) : "0.00"}`,
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        return (rowA.monthlyCharges || 0) - (rowB.monthlyCharges || 0);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Actions</span>,
      minWidth: "110px",
      selector: (row: any) => row,
      cell: (row: any) => {
        return (
          <Button
            className="btn  btn-sm"
            variant="outline-secondary"
            onClick={() => handleBuyNow(row)}
          >
            Buy Now
          </Button>
        );
      },
    },
  ];

  type OptionType = {
    value: string | number;
    label: string;
    stateCode: string | number;
  };

  const sleep = (ms: number) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, ms);
    });

  const allCityOptions = useMemo(() => {
    const options =
      allCity?.length > 0
        ? allCity?.map((dt: { name: string; stateCode: string }) => ({
            label: `${dt?.name} - ${dt?.stateCode}`,
            value: `${dt?.name} - ${dt?.stateCode}`,
            tempValue: dt?.name,
            stateCode: dt?.stateCode,
          }))
        : [];
    return options;
  }, [allCity]);

  const loadOptions = async (search: string, prevOptions: any) => {
    await sleep(200);

    let filteredOptions: OptionType[];
    if (!search) {
      filteredOptions = allCityOptions;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = allCityOptions.filter((x: OptionType) =>
        x.label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      country: "US",
      state: "",
      city: null,
      areaCode: "",
      capabilities: [],
      contains: "",
    },
    validationSchema: Yup.object().shape({
      state: Yup.string().required("State is required"),
      city: Yup.object()
        .required("This field is required")
        .when("state", (state, schema) => {
          if (state) {
            return schema.required("City is required");
          }
          return schema;
        })
        .test(
          "is-valid-object",
          "Must be a valid object",
          (value) =>
            value !== null && typeof value === "object" && !Array.isArray(value)
        ),
    }),
    onSubmit: (values) => {
      handleGetData(1, rowsPerPage, getPayload(values));
    },
  });

  useEffect(() => {
    handleGetData(1, rowsPerPage);
    setAllCountry(Country.getAllCountries());
  }, []);

  useEffect(() => {
    if (validation.values.country) {
      const temp = State.getStatesOfCountry(validation.values.country);
      setAllState(temp);
    }
  }, [validation.values.country]);

  useEffect(() => {
    if (validation.values.state) {
      const temp = City.getCitiesOfState(
        validation.values.country,
        validation.values.state
      );
      setAllCity(temp);
    }
  }, [validation.values.state]);

  useEffect(() => {
    const payload = {
      state: validation.values.state,
      city: validation.values.city?.tempValue || "",
    };
    if (payload.state || payload.city) {
      dispatch(getAreaCodeThunk(removeEmptyAndNullValues(payload)));
    }
  }, [validation.values.state, validation.values.city, dispatch]);

  const getPayload = (values: any) => {
    let updatedPayload: any = {
      tfn: isChecked,
      inRegion: values.state,
      inLocality: values.city?.tempValue || "",
      areaCode: values.areaCode,
      contains: values.contains,
    };
    if (values.capabilities.find((x: string) => x === "sms")) {
      updatedPayload = { ...updatedPayload, smsEnabled: true };
    }
    if (values.capabilities.find((x: string) => x === "voice")) {
      updatedPayload = { ...updatedPayload, voiceEnabled: true };
    }
    if (values.capabilities.find((x: string) => x === "mms")) {
      updatedPayload = { ...updatedPayload, mmsEnabled: true };
    }
    return removeEmptyAndNullValues(updatedPayload);
  };

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      fetchNumbersListThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
          areaCode: otherParams?.areaCode
            ? otherParams.areaCode
            : getAreaCode?.data?.length > 0
            ? getAreaCode?.data?.map((dt: any) => dt?.area_code)
            : "",
        })
      )
    );
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage, getPayload({ ...validation.values }));
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows, getPayload({ ...validation.values }));
  };

  const handleBuyNow = (item: { phoneNumber?: number | string }) => {
    dispatch(
      openModal({
        modalbody: "Are you sure you want to purchase this Number?",
        data: {
          title: "Buy Now",
          footer: true,
          cancelBtn: true,
          buttonText: {
            submit: "Yes",
            cancel: "No",
          },
          onSubmit: ({ onClose }: any) => {
            const cb = () => {
              onClose();
              // validation.resetForm();
              handleGetData(
                currPage,
                rowsPerPage,
                getPayload({
                  ...validation.values,
                })
              );
              !stepsData?.buyFirstNumber &&
                dispatch(
                  updateUserStepsThunk(
                    { buyFirstNumber: true },
                    user?.completedSteps
                  )
                );
            };

            submitLoadingSubject.next(true);
            dispatch(
              purchaseNumberThunk(
                { phoneNumbers: [`${item?.phoneNumber}`], tollFree: false },
                cb
              )
            ).then(() => {
              submitLoadingSubject.next(false);
            });
          },
        },
      })
    );
  };

  const resetAreaCode = () => {
    dispatch(resetAreaCodeThunk());
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Buy Numbers"
            isBack={Boolean(subGroupId)}
            backClick={() =>
              props.router.navigate(
                subGroupId
                  ? `/groups/${groupId}/subgroups/${subGroupId}/${subgroupnames}`
                  : -1
              )
            }
          />

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body>
                <div className="border-bottom pb-2">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setOpen(!open);
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col sm={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="country">Country</Form.Label>
                          <Select
                            name="country"
                            styles={styleObj(
                              validation?.touched?.country &&
                                validation?.errors?.country
                            )}
                            theme={customSelectTheme}
                            options={
                              allCountry?.length > 0
                                ? allCountry?.map(
                                    (dt: {
                                      name: string;
                                      isoCode: string;
                                    }) => ({
                                      label: dt?.name,
                                      value: dt?.isoCode,
                                    })
                                  )
                                : []
                            }
                            onChange={(e: { value: string }) => {
                              validation.setFieldValue(
                                "country",
                                e.value ? e.value : ""
                              );
                              validation.setFieldValue("state", "");
                              validation.setFieldValue("city", null);
                              validation.setFieldValue("areaCode", "");
                            }}
                            value={{
                              label: allCountry?.find(
                                (dt: { isoCode: string }) =>
                                  dt?.isoCode === validation.values.country
                              )?.name,
                              value: validation.values.country,
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="state">States</Form.Label>
                          <Select
                            name="state"
                            styles={styleObj(
                              validation?.touched?.state &&
                                validation?.errors?.state
                            )}
                            theme={customSelectTheme}
                            isDisabled={!validation.values.country}
                            options={
                              allState?.length > 0
                                ? allState?.map(
                                    (dt: {
                                      name: string;
                                      isoCode: string;
                                    }) => ({
                                      label: dt?.name,
                                      value: dt?.isoCode,
                                    })
                                  )
                                : []
                            }
                            onChange={(e: { value: string }) => {
                              validation.setFieldValue("state", e.value);
                              validation.setFieldValue("city", null);
                              validation.setFieldValue("areaCode", "");
                            }}
                            value={{
                              label: allState?.find(
                                (dt: { isoCode: string }) =>
                                  dt?.isoCode === validation.values.state
                              )?.name,
                              value: validation.values.state,
                            }}
                          />
                          {validation.touched.state &&
                            validation.errors.state && (
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                              >
                                {validation.errors.state}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      <Col sm={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="city">City</Form.Label>
                          <AsyncPaginate
                            key={validation.values.state}
                            name="city"
                            styles={styleObj(
                              validation?.touched?.city &&
                                validation?.errors?.city
                            )}
                            theme={customSelectTheme}
                            loadOptions={loadOptions}
                            onChange={(e: {
                              value: string;
                              tempValue: string;
                              stateCode: string;
                            }) => {
                              validation.setFieldValue("city", e);
                              validation.setFieldValue("state", e?.stateCode);
                            }}
                            value={validation.values.city}
                          />
                          {validation.touched.city &&
                            validation.errors.city && (
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                              >
                                {validation.errors.city}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      <Col sm={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="areaCode">
                            {isChecked ? "Toll Free Prefix" : "Area Code"}
                          </Form.Label>
                          {isChecked ? (
                            <Select
                              name="areaCode"
                              styles={styleObj(
                                validation?.touched?.areaCode &&
                                  validation?.errors?.areaCode
                              )}
                              theme={customSelectTheme}
                              options={
                                tfpOpt?.length > 0
                                  ? tfpOpt.map((dt) => ({
                                      label: dt,
                                      value: dt,
                                    }))
                                  : []
                              }
                              onChange={(e: { value: string }) => {
                                validation.setFieldValue("areaCode", e.value);
                              }}
                              value={{
                                label: validation.values.areaCode,
                                value: validation.values.areaCode,
                              }}
                              isDisabled={
                                !validation.values.city?.tempValue ||
                                !validation.values.state
                              }
                            />
                          ) : (
                            <Select
                              name="areaCode"
                              styles={styleObj(
                                validation?.touched?.areaCode &&
                                  validation?.errors?.areaCode
                              )}
                              theme={customSelectTheme}
                              options={
                                getAreaCode?.data?.length > 0
                                  ? getAreaCode.data.map((item: any) => ({
                                      label: `${item.area_code} - ${item.city}, ${item.state}`,
                                      value: item.area_code,
                                      state: item.state,
                                      city: item.city,
                                    }))
                                  : []
                              }
                              onChange={(e: {
                                value: string;
                                city: string;
                                state: string;
                              }) => {
                                validation.setFieldValue("areaCode", e.value);
                                validation.setFieldValue("city", {
                                  label: `${e.city} - ${e.state}`,
                                  value: `${e.city} - ${e.state}`,
                                  tempValue: e.city,
                                  stateCode: e.state,
                                });
                              }}
                              value={
                                getAreaCode?.data?.find(
                                  (item: any) =>
                                    item.area_code ===
                                    validation.values.areaCode
                                )
                                  ? {
                                      label: `${validation.values.areaCode} - ${
                                        getAreaCode.data.find(
                                          (item: any) =>
                                            item.area_code ===
                                            validation.values.areaCode
                                        ).city
                                      }, ${
                                        getAreaCode.data.find(
                                          (item: any) =>
                                            item.area_code ===
                                            validation.values.areaCode
                                        ).state
                                      }`,
                                      value: validation.values.areaCode,
                                    }
                                  : null
                              }
                            />
                          )}
                          {validation.touched.areaCode &&
                            validation.errors.areaCode && (
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                              >
                                {validation.errors.areaCode}
                              </Form.Control.Feedback>
                            )}
                        </div>
                      </Col>

                      <Col sm={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="contains">Ends With</Form.Label>
                          <input
                            type="number"
                            placeholder="Choice Number"
                            className="form-control custom-button"
                            name="contains"
                            value={validation.values.contains || ""}
                            onChange={validation.handleChange}
                          />
                        </div>
                      </Col>

                      <Col sm={4}>
                        <div className="mb-3 mt-4">
                          <Dropdown
                            className="form-control form "
                            id="capabilities"
                            style={{ padding: "0px" }}
                          >
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-capabilities"
                              style={{
                                background: "rgba(0, 0, 0, 0)",
                                color: "#6868ab",
                                border: "1px solid #6868ab",
                                width: "100%",
                                textAlign: "start",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {validation.values.capabilities.length > 0
                                ? validation.values.capabilities.join(", ")
                                : "Advanced Search"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              style={{ width: "100%", padding: "12px" }}
                              id="capabilities"
                            >
                              {capabilitiesOptions.map((key) => (
                                <div className="form-check" key={key.value}>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={key.value}
                                    checked={validation.values.capabilities.includes(
                                      key.value as never
                                    )}
                                    onChange={() => {
                                      const keyIndex =
                                        validation.values.capabilities.indexOf(
                                          key.value as never
                                        );
                                      if (key.value === "all") {
                                        const tempValues =
                                          validation.values.capabilities
                                            .length ===
                                          capabilitiesOptions.length
                                            ? []
                                            : capabilitiesOptions.map(
                                                (x) => x.value as never
                                              );
                                        validation.setFieldValue(
                                          "capabilities",
                                          tempValues
                                        );
                                      } else {
                                        if (keyIndex !== -1) {
                                          const updatedOptions = [
                                            ...validation.values.capabilities,
                                          ];
                                          updatedOptions.splice(keyIndex, 1);
                                          validation.setFieldValue(
                                            "capabilities",
                                            updatedOptions
                                          );
                                        } else {
                                          validation.setFieldValue(
                                            "capabilities",
                                            [
                                              ...validation.values.capabilities,
                                              key.value as never,
                                            ]
                                          );
                                        }
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={key.value}
                                  >
                                    {key.label}
                                  </label>
                                </div>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </Col>
                      {/* <Col className="d-flex align-items-center">
                        <div
                          className="form-check form-switch form-switch-md"
                          dir="ltr"
                        >
                          <Form.Check
                            type="checkbox"
                            role="switch"
                            label={
                              <Form.Label>
                                {isChecked
                                  ? "Toll Free Numbers Enabled"
                                  : "Toll Free Numbers Disabled"}
                              </Form.Label>
                            }
                            checked={isChecked}
                            onChange={(e) => {
                              validation.setFieldValue("areaCode", "");
                              setIsChecked(e.target.checked);
                            }}
                            style={{ marginBottom: "20px" }}
                          />
                        </div>
                      </Col> */}
                      <Col className="mb-2">
                        <div className="d-flex align-items-center justify-content-end">
                          <Button
                            className="btn btn-secondary me-2"
                            type="button"
                            disabled={loading}
                            onClick={() => {
                              setIsChecked(false);
                              validation.resetForm();
                              setOpen(!open);
                              handleGetData(1, rowsPerPage);
                              setAllCity(City.getCitiesOfCountry("US"));
                              resetAreaCode();
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Clear
                          </Button>
                          <Button
                            className="btn btn-primary"
                            type="submit"
                            disabled={loading}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {loading && (
                              <Spinner size="sm" animation="border" />
                            )}{" "}
                            Apply
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Card.Body>

              <Card.Body className="listing-table pt-0">
                {loading ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <>
                    {selectedRows?.length > 0 && (
                      <div className="d-flex align-items-center justify-content-end">
                        <Button
                          className={`btn btn-outline-primary me-2 mb-4 ${
                            selectedRows?.length < 1 ? "cursor-not-allowed" : ""
                          }`}
                          type="button"
                          disabled={selectedRows?.length < 1}
                          onClick={() => {
                            dispatch(
                              openModal({
                                path: "BuyMultipleNumbers",
                                data: {
                                  title: "Buy Multiple Numbers",
                                  footer: false,
                                  selectedRows,
                                  stepsData,
                                  getData: () =>
                                    handleGetData(
                                      currPage,
                                      rowsPerPage,
                                      getPayload({
                                        ...validation.values,
                                      })
                                    ),
                                },
                              })
                            );
                          }}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Buy {selectedRows?.length > 1 ? "Numbers" : "Number"}
                        </Button>
                      </div>
                    )}
                    <Datatable
                      data={
                        Array.isArray(dataObj?.records) ? dataObj?.records : []
                      }
                      columns={columns}
                      handlePageChange={handlePageChange}
                      handleRowsPerPageChange={handleRowsPerPageChange}
                      currPage={currPage}
                      rowsPerPage={rowsPerPage}
                      totalRecords={dataObj?.totalRecords}
                    />
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BuyNumbers);
