import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Card,
  Button,
  Row,
  Col,
  Form,
  Spinner,
  Container,
  Dropdown,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAllMessageLogsThunk } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import Flatpickr from "react-flatpickr";
import {
  asyncBrandList,
  asyncCampaignList,
  asyncSubgroupList,
  capitalizeString,
  dateRangeArr,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { customSelectTheme } from "helpers/common";
import withRouter from "Common/withRouter";
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment";
import Select from "react-select";
import { GroupSelect } from "Common/filter/group-select";
import { BrandSelect } from "Common/filter/brand-select";
import MessageTable from "./MessageTable";

const selectedTypeArr = ["All", "SMS", "MMS"];
const selectedTypeArr2 = ["All", "Outbound", "Inbound"];
const phoneNumberWithPlus = /^\+\w+/;

const MessageLogsList = (props: any) => {
  document.title = "Signal House Portal Messaging Logs";

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(selectedTypeArr[0]);
  const [selectedType2, setSelectedType2] = useState(selectedTypeArr2[0]);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [dateRangeTemp, setDateRangeTemp] = useState<any>(null);

  const selectData = createSelector(
    (state: any) => state.Messages,
    (state: any) => state.Groups,
    (messages, groups) => ({
      loading2: messages.loading2,
      groupDetails: groups.AllGroups,
      allMessageLogs: messages.allMessageLogs,
    })
  );

  const { loading2, groupDetails, allMessageLogs } = useSelector(selectData);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      signalHouseSID: "",
      from: "",
      to: "",
      subGroupId: [],
      brandId: [],
      campaignId: [],
      status: [],
      createdAt: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      setOpen(false);
      handleGetData(
        1,
        rowsPerPage,
        removeEmptyAndNullValues({
          ...values,
          subGroupId: values.subGroupId.length > 0 ? values.subGroupId : "",
          brandId:
            values.brandId.length > 0
              ? values.brandId?.map((dt: any) => dt.brandId)
              : "",
          campaignId:
            values.campaignId.length > 0
              ? values.campaignId?.map((dt: any) => dt.campaignId)
              : "",
          status: values.status.length > 0 ? values.status : "",
        })
      );
    },
  });

  useEffect(() => {
    (selectedType || selectedType2) &&
      handleGetData(
        1,
        rowsPerPage,
        removeEmptyAndNullValues({
          ...validation.values,
          subGroupId:
            validation.values.subGroupId.length > 0
              ? validation.values.subGroupId
              : "",
          brandId:
            validation.values.brandId.length > 0
              ? validation.values.brandId?.map((dt: any) => dt.brandId)
              : "",
          campaignId:
            validation.values.campaignId.length > 0
              ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
              : "",
          status:
            validation.values.status.length > 0 ? validation.values.status : "",
        })
      );
  }, [selectedType, selectedType2]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    const tempstartDate = otherParams.startDate
      ? moment(otherParams.startDate, "MM-DD-YYYY").startOf("day").toISOString()
      : "";
    const tempendDate = otherParams.endDate
      ? moment(otherParams.endDate, "MM-DD-YYYY").endOf("day").toISOString()
      : "";
    const formattedFrom = otherParams.from
      ? phoneNumberWithPlus.test(otherParams.from)
        ? `%2B${otherParams.from.split("+")[1]}`
        : otherParams.from
      : "";
    const formattedTo = otherParams.to
      ? phoneNumberWithPlus.test(otherParams.to)
        ? `%2B${otherParams.to.split("+")[1]}`
        : otherParams.to
      : "";

    const allParams = {
      ...otherParams,
      type: selectedType === "All" ? "" : selectedType,
      direction: selectedType2 === "All" ? "" : selectedType2.toLowerCase(),
      from: formattedFrom,
      to: formattedTo,
      createdAt: "",
      startDate: tempstartDate,
      endDate: tempendDate,
    };

    dispatch(
      getAllMessageLogsThunk(page, perPage, removeEmptyAndNullValues(allParams))
    );
  };

  const handleRowClick = (row: { type: string; signalHouseSID: string }) => {
    row.type === "SMS"
      ? navigate(`/sms-logs-details/${row.signalHouseSID}`)
      : navigate(`/mms-logs-details/${row.signalHouseSID}`);
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(
      page,
      rowsPerPage,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        status:
          validation.values.status.length > 0 ? validation.values.status : "",
      })
    );
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        status:
          validation.values.status.length > 0 ? validation.values.status : "",
      })
    );
  };

  const customSort = (rows: any, selector: any, direction: string) => {
    return rows.sort((rowA: any, rowB: any) => {
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center mb-1 me-3">
              <div className="d-flex align-items-center mb-1">
                <h4 className="mb-0 me-3">Messaging Logs</h4>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Analytics filters"
                >
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Message type"
                  >
                    {selectedTypeArr
                      ?.filter((dt) => dt !== "All")
                      ?.map((dt, i) => (
                        <Button
                          key={i}
                          className={`btn me-1 ${
                            selectedType === dt || selectedType === "All"
                              ? "btn-secondary"
                              : "btn-outline-secondary hover-button"
                          }`}
                          onClick={() => {
                            if (selectedType === dt || selectedType === "All") {
                              const otherType = selectedTypeArr.find(
                                (t) => t !== "All" && t !== dt
                              );
                              setSelectedType(otherType || "All");
                            } else {
                              setSelectedType(
                                selectedType === "All" ? dt : "All"
                              );
                            }
                          }}
                        >
                          {dt === "SMS" ? (
                            <i className="bi bi-chat-left-text" />
                          ) : (
                            <i className="bi bi-file-image" />
                          )}
                          <span className="ms-1 d-none d-sm-inline">{dt}</span>
                        </Button>
                      ))}
                  </div>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Message direction"
                  >
                    {selectedTypeArr2
                      ?.filter((dt) => dt !== "All")
                      ?.map((dt, i) => (
                        <Button
                          key={i}
                          className={`btn me-1 ${
                            selectedType2 === dt || selectedType2 === "All"
                              ? "btn-secondary"
                              : "btn-outline-secondary hover-button"
                          }`}
                          onClick={() => {
                            if (
                              selectedType2 === dt ||
                              selectedType2 === "All"
                            ) {
                              const otherType = selectedTypeArr2.find(
                                (t) => t !== "All" && t !== dt
                              );
                              setSelectedType2(otherType || "All");
                            } else {
                              setSelectedType2(
                                selectedType2 === "All" ? dt : "All"
                              );
                            }
                          }}
                        >
                          <i
                            className={`bi bi-send${
                              dt === "Inbound" ? "-fill" : ""
                            }`}
                          />
                          <span className="ms-1 d-none d-sm-inline">{dt}</span>
                        </Button>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ flex: "1 1 0%" }}
            >
              <div className="d-flex align-items-center flex-wrap">
                {validation.values.from && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    From: {validation.values.from}
                  </span>
                )}
                {validation.values.to && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    To: {validation.values.to}
                  </span>
                )}
                {validation.values.subGroupId?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Subgroup Id: {validation.values.subGroupId?.join(", ")}
                  </span>
                )}
                {validation.values.brandId?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Brand Id:{" "}
                    {validation.values.brandId
                      ?.map((dt: any) => dt.brandId)
                      ?.join(", ")}
                  </span>
                )}
                {validation.values.campaignId?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Campaign Id:{" "}
                    {validation.values.campaignId
                      ?.map((dt: any) => dt.campaignId)
                      ?.join(", ")}
                  </span>
                )}
                {validation.values.status?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Status: {validation.values.status?.join(", ")}
                  </span>
                )}
                {dateRangeTemp?.value ? (
                  dateRangeTemp?.value === "Custom" ? (
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      Date Range: {validation.values.startDate}
                      {validation.values.endDate
                        ? ` to ${validation.values.endDate}`
                        : ""}
                    </span>
                  ) : (
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      Date Range: {dateRangeTemp?.value}
                    </span>
                  )
                ) : null}
              </div>
              <Dropdown
                align="end"
                className="ms-2 filterDropDown2"
                show={open}
                onToggle={() => {
                  if (!datePickerModal) {
                    setOpen(!open);
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <div className="mb-3">
                        <Form.Label htmlFor="signalHouseSID">Search</Form.Label>
                        <div className="d-flex">
                          <input
                            type="text"
                            placeholder="Search by Message SID"
                            className="form-control"
                            {...validation.getFieldProps("signalHouseSID")}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <Button className="btn btn-primary mb-3" type="submit">
                          Search
                        </Button>
                      </div>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="from">From</Form.Label>
                        <input
                          type="number"
                          placeholder="From"
                          className="form-control"
                          {...validation.getFieldProps("from")}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="to">To</Form.Label>
                        <input
                          type="number"
                          placeholder="To"
                          className="form-control"
                          {...validation.getFieldProps("to")}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                        <GroupSelect
                          isDisabled={!groupDetails?.records?.[0]?.group_id}
                          key={groupDetails?.records?.[0]?.group_id}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.subGroupId &&
                              validation?.errors?.subGroupId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.subGroupId?.length > 0
                              ? validation.values.subGroupId.map(
                                  (dt: string) => ({
                                    sub_group_id: dt,
                                  })
                                )
                              : null
                          }
                          name="subGroupId"
                          loadOptions={asyncSubgroupList(
                            removeEmptyAndNullValues({
                              groupId:
                                groupDetails?.records?.[0]?.group_id || "",
                            }),
                            "subGroupId"
                          )}
                          getOptionValue={(option: any) => option?.sub_group_id}
                          getOptionLabel={(option: any) =>
                            option?.sub_group_name +
                            " - " +
                            option?.sub_group_id
                          }
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.sub_group_id !==
                                validation.values.subGroupId
                            ) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.map((dt: any) => dt.sub_group_id)
                              );
                              validation.setFieldValue("brandId", []);
                              validation.setFieldValue("campaignId", []);
                            } else if (!option) {
                              validation.setFieldValue("subGroupId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand</Form.Label>
                        <BrandSelect
                          key={
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "brandId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId?.length > 0
                              ? validation.values.brandId
                              : null
                          }
                          loadOptions={asyncBrandList(
                            removeEmptyAndNullValues({
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                            }),
                            "brandId"
                          )}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) =>
                            option?.displayName + " - " + option?.brandId
                          }
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.brandId === validation.values.brandId
                            ) {
                              return;
                            }
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                }))
                              );
                              validation.setFieldValue("campaignId", []);
                            } else {
                              validation.setFieldValue("brandId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "campaignId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.campaignId &&
                              validation?.errors?.campaignId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.campaignId?.length > 0
                              ? validation.values.campaignId
                              : null
                          }
                          loadOptions={asyncCampaignList(
                            removeEmptyAndNullValues({
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                            }),
                            "campaignId"
                          )}
                          getOptionValue={(option: any) => option?.campaignId}
                          getOptionLabel={(option: any) => option?.campaignId}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.campaignId === validation.values.campaignId
                            ) {
                              return;
                            }
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "campaignId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  campaignId: dt?.campaignId || "",
                                }))
                              );
                            } else {
                              validation.setFieldValue("campaignId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="status">Status</Form.Label>
                        <Select
                          name="status"
                          isMulti={true}
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.status &&
                              validation?.errors?.status
                          )}
                          theme={customSelectTheme}
                          options={[
                            { label: "Sent", value: "sent" },
                            { label: "Created", value: "created" },
                            { label: "Failed", value: "failed" },
                            { label: "Delivered", value: "delivered" },
                            { label: "Received", value: "received" },
                            { label: "Enqueued", value: "enqueued" },
                            { label: "Dequeued", value: "dequeued" },
                          ]}
                          onChange={(selectedOptions: any) => {
                            if (selectedOptions && selectedOptions.length > 0) {
                              validation.setFieldValue(
                                "status",
                                selectedOptions.map(
                                  (option: any) => option.value
                                )
                              );
                            } else {
                              validation.setFieldValue("status", []);
                            }
                          }}
                          value={
                            validation.values.status?.length > 0
                              ? validation.values.status.map(
                                  (status: string) => ({
                                    label: capitalizeString(status),
                                    value: status,
                                  })
                                )
                              : null
                          }
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="dateRange">Date Range</Form.Label>
                        <Select
                          isClearable={true}
                          styles={styleObj(false)}
                          theme={customSelectTheme}
                          options={dateRangeArr}
                          onChange={(e: any) => {
                            setDateRangeTemp(e);
                            let startDate = "";
                            let endDate = moment()
                              .endOf("day")
                              .format("MM-DD-YYYY");

                            if (e?.value === "24 hours") {
                              startDate = moment()
                                .subtract(1, "days")
                                .startOf("day")
                                .format("MM-DD-YYYY");
                            } else if (e?.value === "7 days") {
                              startDate = moment()
                                .subtract(7, "days")
                                .startOf("day")
                                .format("MM-DD-YYYY");
                            } else if (e?.value === "30 days") {
                              startDate = moment()
                                .subtract(30, "days")
                                .startOf("day")
                                .format("MM-DD-YYYY");
                            } else if (e?.value === "120 days") {
                              startDate = moment()
                                .subtract(120, "days")
                                .startOf("day")
                                .format("MM-DD-YYYY");
                            } else {
                              endDate = "";
                            }

                            validation.setFieldValue("createdAt", "");
                            validation.setFieldValue("startDate", startDate);
                            validation.setFieldValue("endDate", endDate);
                          }}
                          value={dateRangeTemp}
                        />
                      </Col>
                      {dateRangeTemp?.value === "Custom" ? (
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="createdAt">
                            Select Range
                          </Form.Label>
                          <Flatpickr
                            className="form-control"
                            name="createdAt"
                            placeholder="Select Range"
                            options={{
                              enableTime: false,
                              onOpen: () => {
                                setDatePickerModal(true);
                              },
                              onClose: () => {
                                setDatePickerModal(false);
                              },
                              altFormat: "F j, Y",
                              dateFormat: "m-d-Y",
                              mode: "range",
                              onChange: (
                                value: any,
                                dateStr: string,
                                instance: any
                              ) => {
                                validation.setFieldValue(
                                  "createdAt",
                                  dateStr || ""
                                );
                                value?.[0] &&
                                  validation.setFieldValue(
                                    "startDate",
                                    moment(value?.[0]).format("MM-DD-YYYY")
                                  );
                                value?.[1] &&
                                  validation.setFieldValue(
                                    "endDate",
                                    moment(value?.[1]).format("MM-DD-YYYY")
                                  );
                              },
                            }}
                            value={validation.values.createdAt || ""}
                          />
                        </Col>
                      ) : null}
                    </Row>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading2}
                        onClick={() => {
                          handleGetData(
                            currPage,
                            rowsPerPage,
                            removeEmptyAndNullValues({
                              ...validation.initialValues,
                              subGroupId: "",
                              brandId: "",
                              campaignId: "",
                              status: "",
                            })
                          );
                          validation.resetForm();
                          setDatePickerModal(false);
                          setDateRangeTemp(null);
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading2}
                      >
                        {loading2 && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading2 ? (
                  <div>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <MessageTable
                    data={
                      Array.isArray(allMessageLogs.records)
                        ? allMessageLogs.records
                        : []
                    }
                    handleRowClick={handleRowClick}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={allMessageLogs?.totalRecords}
                    customSort={customSort}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(MessageLogsList);
