import { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import frame2 from "assets/images/frame1.png";

function MobilePreview({ validation1, validation2 }: any) {
  const [activeTab, setActiveTab] = useState<string>("info");

  return (
    <div className="preview">
      <style>
        {`
          .preview .nav-primary.nav-tabs-custom .nav-link.active,
          .preview .nav-primary.nav-tabs-custom .nav-link:hover {
            color: ${validation2.values.brandColor} !important;
          }
          .preview .nav-primary.nav-tabs-custom .nav-link.active::after,
          .preview .nav-primary.nav-tabs-custom .nav-link:hover::after {
            background-color: ${validation2.values.brandColor} !important;
          }
        `}
      </style>
      <Card>
        <Card.Body>
          <div className="position-relative w-fit d-flex m-auto">
            <img
              src={frame2}
              alt="mobile-pic"
              height={620}
              className="position-relative pointer-events-none z-3"
            />
            <div
              className="overflow-hidden position-absolute top-0 bottom-0 start-0 end-0 mb-0 p-2 z-2"
              style={{ borderRadius: 65 }}
            >
              <div className="align-items-center d-flex px-3 pb-3 pt-5 position-relative">
                <i
                  className="mdi mdi-signal position-absolute fs-md text-black"
                  style={{ top: 11, right: 60 }}
                />
                <i
                  className="ri-wifi-fill position-absolute fs-md text-black"
                  style={{ top: 11, right: 44 }}
                />
                <i
                  className="bi bi-battery-full position-absolute fs-xl text-black"
                  style={{ top: 10, right: 23 }}
                />
                <div className="flex-grow-1 mt-2">
                  <div className="fs-lg fw-semibold">Info & Options</div>
                </div>
              </div>
              <div className="p-0">
                {validation2.values.agentBanner && (
                  <img
                    src={
                      validation2.values.agentBanner
                        ? URL.createObjectURL(validation2.values.agentBanner)
                        : ""
                    }
                    alt="banner-pic"
                    style={{ width: "100%" }}
                  />
                )}
                {validation2.values.agentLogo && (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ marginTop: "-45px" }}
                  >
                    <img
                      src={URL.createObjectURL(validation2.values.agentLogo)}
                      alt="logo-pic"
                      className="avatar-md rounded-circle p-1 border-0 img-thumbnail object-fit-cover mx-auto"
                    />
                  </div>
                )}
                <div className="p-2 pt-3 mb-3">
                  <p className="fs-xl fw-semibold text-center">
                    {validation1.values.legalCompanyName}
                  </p>
                  <div className="d-flex justify-content-around">
                    <div
                      className="text-center mx-2"
                      style={{ color: validation2.values.brandColor }}
                    >
                      <i className="bi bi-telephone fs-4xl" />
                      <p className="mb-0">Call</p>
                    </div>
                    <div
                      className="text-center mx-2"
                      style={{ color: validation2.values.brandColor }}
                    >
                      <i className="bi bi-globe fs-4xl" />
                      <p className="mb-0">Website</p>
                    </div>
                    <div
                      className="text-center mx-2"
                      style={{ color: validation2.values.brandColor }}
                    >
                      <i className="bi bi-envelope fs-4xl" />
                      <p className="mb-0">Email</p>
                    </div>
                  </div>
                </div>
                <Tab.Container
                  defaultActiveKey={activeTab}
                  activeKey={activeTab}
                >
                  <Nav
                    as="ul"
                    variant="tabs"
                    className="nav-tabs-custom nav-primary nav-justified w-100"
                  >
                    <Nav.Item
                      as="li"
                      className="d-flex justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setActiveTab("info");
                      }}
                    >
                      <Nav.Link eventKey="info">Info</Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      as="li"
                      className="d-flex justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setActiveTab("options");
                      }}
                    >
                      <Nav.Link eventKey="options">Options</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="info">
                      <SimpleBar
                        style={{ maxHeight: "200px" }}
                        className="pe-2"
                      >
                        <div className="text-reset notification-item d-block dropdown-item position-relative">
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <i className="bi bi-telephone fs-2xl ms-2" />
                            </div>
                            <div className="ms-3 flex-grow-1">
                              <p
                                className="mt-0 fs-md mb-1 cursor-pointer"
                                style={{ wordBreak: "break-word" }}
                                onClick={() =>
                                  validation2.values.cfPhone &&
                                  window.open(
                                    `tel:${validation2.values.cfPhone}`,
                                    "_blank"
                                  )
                                }
                              >
                                {validation2.values.cfPhone}
                              </p>
                              <p className="mb-0 fs-sm text-muted">
                                {validation2.values.cfPhoneLabel}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="text-reset notification-item d-block dropdown-item position-relative">
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <i className="bi bi-globe fs-2xl ms-2" />
                            </div>
                            <div className="ms-3 flex-grow-1">
                              <p
                                className="mt-0 fs-md mb-1 cursor-pointer"
                                style={{ wordBreak: "break-word" }}
                                onClick={() =>
                                  validation2.values.companyWebsite &&
                                  window.open(
                                    validation2.values.companyWebsite,
                                    "_blank"
                                  )
                                }
                              >
                                {validation2.values.companyWebsite}
                              </p>
                              <p className="mb-0 fs-sm text-muted">
                                {validation2.values.companyWebsiteLabel}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="text-reset notification-item d-block dropdown-item position-relative">
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <i className="bi bi-envelope fs-2xl ms-2" />
                            </div>
                            <div className="ms-3 flex-grow-1">
                              <p
                                className="mt-0 fs-md mb-1 cursor-pointer"
                                style={{ wordBreak: "break-word" }}
                                onClick={() =>
                                  validation2.values.cfEmail &&
                                  window.open(
                                    `mailto:${validation2.values.cfEmail}`,
                                    "_blank"
                                  )
                                }
                              >
                                {validation2.values.cfEmail}
                              </p>
                              <p className="mb-0 fs-sm text-muted">
                                {validation2.values.cfEmailLabel}
                              </p>
                            </div>
                          </div>
                        </div>
                      </SimpleBar>
                    </Tab.Pane>
                    <Tab.Pane eventKey="options">
                      <SimpleBar
                        style={{ maxHeight: "200px" }}
                        className="pe-2"
                      >
                        <div className="text-reset notification-item dropdown-item py-3">
                          <p
                            className="mt-0 fs-md mb-0"
                            style={{ wordBreak: "break-word" }}
                          >
                            Notifications
                          </p>
                        </div>
                        <div className="text-reset notification-item dropdown-item py-3">
                          <p
                            className="mt-0 fs-md mb-4"
                            style={{ wordBreak: "break-word" }}
                          >
                            App settings
                          </p>
                          <p className="mb-3 fs-sm text-muted fw-semibold">
                            BUSINESS
                          </p>
                          <p
                            className="mt-0 fs-md mb-0"
                            style={{ wordBreak: "break-word" }}
                          >
                            Block & report spam
                          </p>
                        </div>
                        <div
                          className="text-reset notification-item dropdown-item py-3 cursor-pointer"
                          onClick={() =>
                            validation2.values.privacyPolicyLink &&
                            window.open(
                              validation2.values.privacyPolicyLink,
                              "_blank"
                            )
                          }
                        >
                          <p
                            className="mt-0 fs-md mb-0"
                            style={{ wordBreak: "break-word" }}
                          >
                            View Privacy Policy
                          </p>
                        </div>
                        <div
                          className="text-reset notification-item dropdown-item py-3 cursor-pointer"
                          onClick={() =>
                            validation2.values.termsofServiceLink &&
                            window.open(
                              validation2.values.termsofServiceLink,
                              "_blank"
                            )
                          }
                        >
                          <p
                            className="mt-0 fs-md mb-0"
                            style={{ wordBreak: "break-word" }}
                          >
                            View Terms of Service
                          </p>
                        </div>
                        <div className="text-reset notification-item dropdown-item py-3">
                          <p
                            className="mt-0 fs-md mb-0"
                            style={{ wordBreak: "break-word" }}
                          >
                            Learn More
                          </p>
                        </div>
                      </SimpleBar>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default MobilePreview;
