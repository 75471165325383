import { useEffect, useState } from "react";
import {
  Card,
  Container,
  Button,
  Dropdown,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { getAllMsgShortLinkThunk, openModal } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import BreadCrumb from "Common/BreadCrumb";
import {
  asyncBrandList,
  asyncSubgroupList,
  dateFormat,
  removeEmptyAndNullValues,
} from "helpers/common";
import Datatable from "Common/Datatable";
import Loader from "assets/images/spinner-dark.svg";
import { toast } from "react-toastify";
import Tooltip from "rc-tooltip";
import { useFormik } from "formik";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { GroupSelect } from "Common/filter/group-select";
import { BrandSelect } from "Common/filter/brand-select";
import { customSelectTheme, styleObj, asyncCampaignList } from "helpers/common";
import { useSearchParams } from "react-router-dom";

const ShortURL = () => {
  document.title = "Signal House Portal URL Shortener";
  const dispatch = useDispatch<any>();

  const selectAccount = createSelector(
    (state: any) => state.Messages,
    (state: any) => state.Groups,

    (messages, groups) => ({
      loading: messages.loading3,
      shortLinkData: messages.shortLinkData,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, shortLinkData, groupDetails } = useSelector(selectAccount);

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("groupId");
  const subGroupId = searchParams.get("subGroupId");
  const brandId = searchParams.get("brandId");
  const campaignId = searchParams.get("campaignId");

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      subGroupId: [],
      brandId: [],
      brandName: [],
      campaignId: [],
      number: "",
      dateRange: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      handleGetData(currPage, rowsPerPage, values);
    },
  });

  const dateRangeOptions = [
    { label: "Last 7 days", value: "7" },
    { label: "Last 30 days", value: "30" },
    { label: "Last 120 days", value: "120" },
    { label: "Custom Range", value: "custom" },
  ];

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">URL Shortener</span>,
      minWidth: "300px",
      selector: (row: { shortUrl: string }) => row.shortUrl,
      cell: (row: { shortUrl: string }) => (
        <span data-tag="allowRowEvents" className="text-primary">
          <span
            data-tag="allowRowEvents"
            className="fw-semibold"
            id={row.shortUrl}
          >
            <a
              href={row.shortUrl || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.shortUrl || "-"}
            </a>
          </span>
          <i
            className="bx bxs-copy align-middle ms-2"
            onClick={() => copyText(row.shortUrl)}
          ></i>
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.shortUrl.localeCompare(b.shortUrl),
    },
    {
      name: <span className="font-weight-bold fs-sm">Original URL</span>,
      minWidth: "350px",
      selector: (row: { originalUrl: string }) => row.originalUrl,
      cell: (row: { originalUrl: string }) => (
        <Tooltip
          placement="topLeft"
          overlay={
            <p className="mb-0" style={{ wordBreak: "break-word" }}>
              {row.originalUrl || "-"}
            </p>
          }
        >
          <div className="text-secondary text-truncate-two-lines">
            <b data-tag="allowRowEvents">
              <a
                href={row.originalUrl || "#"}
                target="_blank"
                rel="noopener noreferrer"
                className="text-secondary"
              >
                {row.originalUrl || "-"}
              </a>
            </b>
          </div>
        </Tooltip>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.originalUrl.localeCompare(b.originalUrl),
    },
    {
      name: <span className="font-weight-bold fs-sm">Friendly Name</span>,
      minWidth: "150px",
      selector: (row: { friendlyName: string }) => row.friendlyName,
      cell: (row: { friendlyName: string }) => (
        <span data-tag="allowRowEvents" className="text-truncate-two-lines">
          {row.friendlyName || "-"}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.friendlyName.localeCompare(b.friendlyName),
    },
    {
      name: <span className="font-weight-bold fs-sm">Email ID</span>,
      minWidth: "350px",
      selector: (row: { emailId: string }) => row.emailId,
      cell: (row: { emailId: string }) => (
        <span data-tag="allowRowEvents" className="line-clamp-1">
          {row.emailId || "-"}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => a.emailId.localeCompare(b.emailId),
    },
    {
      name: <span className="font-weight-bold fs-sm">Unique Clicks</span>,
      width: "100px",
      selector: (row: { visits: string }) => row.visits,
      cell: (row: { visits: number }) => row.visits || 0,
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (a.visits || 0) - (b.visits || 0);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Create Date</span>,
      minWidth: "220px",
      selector: (row: { createdAt: string }) => row.createdAt,
      cell: (row: { createdAt: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createdAt, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    },
  ];

  useEffect(() => {
    handleGetData(currPage, rowsPerPage, {
      ...validation.values,
      subGroupId: subGroupId ? [subGroupId] : [],
      brandId: brandId
        ? [
            {
              subGroupId: subGroupId || "",
              brandId: brandId || "",
            },
          ]
        : [],
      campaignId: campaignId
        ? [
            {
              subGroupId: subGroupId || "",
              brandId: brandId || "",
              campaignId: campaignId || "",
            },
          ]
        : [],
    });
  }, []);

  useEffect(() => {
    subGroupId && validation.setFieldValue("subGroupId", [subGroupId]);
    brandId &&
      validation.setFieldValue("brandId", [
        {
          subGroupId: subGroupId || "",
          brandId: brandId || "",
        },
      ]);
    campaignId &&
      validation.setFieldValue("campaignId", [
        {
          subGroupId: subGroupId || "",
          brandId: brandId || "",
          campaignId: campaignId || "",
        },
      ]);
  }, [subGroupId, brandId, campaignId]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getAllMsgShortLinkThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
          subGroupId:
            otherParams?.subGroupId?.length > 0 ? otherParams.subGroupId : "",
          brandId:
            otherParams?.brandId?.length > 0
              ? otherParams.brandId?.map((dt: any) => dt.brandId)
              : "",
          brandName:
            otherParams?.brandName?.length > 0
              ? otherParams.brandName?.map((dt: any) => dt.displayName)
              : "",
          campaignId:
            otherParams?.campaignId?.length > 0
              ? otherParams.campaignId?.map((dt: any) => dt.campaignId)
              : "",
          number: otherParams?.number || "",
          startDate: otherParams?.startDate
            ? moment(otherParams?.startDate, "MM-DD-YYYY")
                .startOf("day")
                .toISOString()
            : "",
          endDate: otherParams?.endDate
            ? moment(otherParams?.endDate, "MM-DD-YYYY")
                .endOf("day")
                .toISOString()
            : "",
        })
      )
    );
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  const copyText = (type: string) => {
    var text = document.getElementById(type)?.innerText;
    var elem = document.createElement("textarea");
    document.body.appendChild(elem);
    if (elem && text) {
      elem.value = text;
      elem.select();
      toast.success("Link copied");
    }
    document.execCommand("copy");
    document.body.removeChild(elem);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title="URL Shortener"
          isFilter={true}
          filterButton={
            <Dropdown
              align="end"
              className="filterDropDown2"
              show={open}
              onToggle={() => {
                if (!datePickerModal) {
                  setOpen(!open);
                }
              }}
            >
              <Dropdown.Toggle variant="secondary">
                <i className="bx bx-filter-alt me-1"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu-md p-4">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setOpen(!open);
                    validation.handleSubmit();
                  }}
                >
                  <Row>
                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                      <GroupSelect
                        isDisabled={!groupDetails?.records?.[0]?.group_id}
                        key={groupDetails?.records?.[0]?.group_id}
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        styles={styleObj(
                          validation?.touched?.subGroupId &&
                            validation?.errors?.subGroupId
                        )}
                        theme={customSelectTheme}
                        value={
                          validation.values.subGroupId?.length > 0
                            ? validation.values.subGroupId.map(
                                (dt: string) => ({
                                  sub_group_id: dt,
                                })
                              )
                            : null
                        }
                        name="subGroupId"
                        loadOptions={asyncSubgroupList(
                          removeEmptyAndNullValues({
                            groupId: groupDetails?.records?.[0]?.group_id || "",
                          }),
                          "subGroupId"
                        )}
                        getOptionValue={(option: any) => option?.sub_group_id}
                        getOptionLabel={(option: any) =>
                          option?.sub_group_name + " - " + option?.sub_group_id
                        }
                        onChange={(option: any) => {
                          if (
                            option &&
                            option.sub_group_id !== validation.values.subGroupId
                          ) {
                            validation.setFieldValue(
                              "subGroupId",
                              option.map((dt: any) => dt.sub_group_id)
                            );
                            validation.setFieldValue("brandId", []);
                            validation.setFieldValue("brandName", []);
                            validation.setFieldValue("campaignId", []);
                          } else if (!option) {
                            validation.setFieldValue("subGroupId", []);
                          }
                        }}
                        additional={{
                          page: 1,
                        }}
                      />
                    </Col>

                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="brandId">Brand</Form.Label>
                      <BrandSelect
                        key={
                          validation.values.subGroupId.toString() ||
                          groupDetails?.records?.[0]?.group_id ||
                          "brandId"
                        }
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        styles={styleObj(
                          validation?.touched?.brandId &&
                            validation?.errors?.brandId
                        )}
                        theme={customSelectTheme}
                        value={
                          validation.values.brandId?.length > 0
                            ? validation.values.brandId
                            : null
                        }
                        loadOptions={asyncBrandList(
                          removeEmptyAndNullValues({
                            subGroupId:
                              validation.values.subGroupId.length > 0
                                ? validation.values.subGroupId
                                : "",
                          }),
                          "brandId"
                        )}
                        getOptionValue={(option: any) => option?.brandId}
                        getOptionLabel={(option: any) =>
                          option?.displayName + " - " + option?.brandId
                        }
                        onChange={(option: any) => {
                          if (option) {
                            validation.setFieldValue(
                              "subGroupId",
                              option.reduce(
                                (acc: string[], dt: any) => {
                                  if (
                                    dt?.subGroupId &&
                                    Array.isArray(dt.subGroupId)
                                  ) {
                                    dt.subGroupId.forEach((subGroup: any) => {
                                      if (
                                        !acc.includes(subGroup) &&
                                        !validation.values.subGroupId.includes(
                                          subGroup
                                        )
                                      ) {
                                        acc.push(subGroup);
                                      }
                                    });
                                  }
                                  return acc;
                                },
                                [...validation.values.subGroupId]
                              )
                            );
                            validation.setFieldValue(
                              "brandId",
                              option.map((dt: any) => ({
                                subGroupId: dt?.subGroupId || [],
                                brandId: dt?.brandId || "",
                                displayName: dt?.displayName || "",
                              }))
                            );
                            validation.setFieldValue(
                              "brandName",
                              option.map((dt: any) => ({
                                subGroupId: dt?.subGroupId || [],
                                brandId: dt?.brandId || "",
                                displayName: dt?.displayName || "",
                              }))
                            );
                            validation.setFieldValue("campaignId", []);
                          } else {
                            validation.setFieldValue("brandId", []);
                          }
                        }}
                        additional={{
                          page: 1,
                        }}
                      />
                    </Col>

                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                      <AsyncPaginate
                        key={
                          validation.values.brandId
                            ?.map((dt: any) => dt.brandId)
                            .toString() ||
                          validation.values.subGroupId.toString() ||
                          groupDetails?.records?.[0]?.group_id ||
                          "campaignId"
                        }
                        isMulti={true}
                        isClearable={true}
                        isSearchable={true}
                        styles={styleObj(
                          validation?.touched?.campaignId &&
                            validation?.errors?.campaignId
                        )}
                        theme={customSelectTheme}
                        value={
                          validation.values.campaignId?.length > 0
                            ? validation.values.campaignId
                            : null
                        }
                        loadOptions={asyncCampaignList(
                          removeEmptyAndNullValues({
                            subGroupId:
                              validation.values.subGroupId.length > 0
                                ? validation.values.subGroupId
                                : "",
                            brandId:
                              validation.values.brandId.length > 0
                                ? validation.values.brandId?.map(
                                    (dt: any) => dt.brandId
                                  )
                                : "",
                          }),
                          "campaignId"
                        )}
                        getOptionValue={(option: any) => option?.campaignId}
                        getOptionLabel={(option: any) => option?.campaignId}
                        onChange={(option: any) => {
                          if (option) {
                            validation.setFieldValue(
                              "subGroupId",
                              option.reduce(
                                (acc: string[], dt: any) => {
                                  if (
                                    dt?.subGroupId &&
                                    Array.isArray(dt.subGroupId)
                                  ) {
                                    dt.subGroupId.forEach(
                                      (subGroup: string) => {
                                        if (
                                          !acc.includes(subGroup) &&
                                          !validation.values.subGroupId.includes(
                                            subGroup
                                          )
                                        ) {
                                          acc.push(subGroup);
                                        }
                                      }
                                    );
                                  }
                                  return acc;
                                },
                                [...validation.values.subGroupId]
                              )
                            );
                            validation.setFieldValue(
                              "brandId",
                              option.reduce(
                                (acc: any[], dt: any) => {
                                  const existingItem =
                                    validation.values.brandId.find(
                                      (item: any) =>
                                        item.brandId === dt.brandId &&
                                        JSON.stringify(item.subGroupId) ===
                                          JSON.stringify(dt.subGroupId)
                                    );
                                  if (!existingItem) {
                                    acc.push({
                                      subGroupId: dt?.subGroupId || [],
                                      brandId: dt?.brandId || "",
                                      displayName:
                                        dt?.displayName || dt?.brandName || "",
                                    });
                                  }
                                  return acc;
                                },
                                [...validation.values.brandId]
                              )
                            );
                            validation.setFieldValue(
                              "brandName",
                              option.reduce(
                                (acc: any[], dt: any) => {
                                  const existingItem =
                                    validation.values.brandId.find(
                                      (item: any) =>
                                        item.brandId === dt.brandId &&
                                        JSON.stringify(item.subGroupId) ===
                                          JSON.stringify(dt.subGroupId)
                                    );
                                  if (!existingItem) {
                                    acc.push({
                                      subGroupId: dt?.subGroupId || [],
                                      brandId: dt?.brandId || "",
                                      displayName:
                                        dt?.displayName || dt?.brandName || "",
                                    });
                                  }
                                  return acc;
                                },
                                [...validation.values.brandId]
                              )
                            );

                            validation.setFieldValue(
                              "campaignId",
                              option.map((dt: any) => ({
                                subGroupId: dt?.subGroupId || [],
                                brandId: dt?.brandId || "",
                                displayName:
                                  dt?.displayName || dt?.brandName || "",
                                campaignId: dt?.campaignId || "",
                              }))
                            );
                          } else {
                            validation.setFieldValue("campaignId", []);
                          }
                        }}
                        additional={{
                          page: 1,
                        }}
                      />
                    </Col>

                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="number">Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={validation.values.number}
                        onChange={(e) =>
                          validation.setFieldValue("number", e.target.value)
                        }
                        style={
                          validation?.touched?.number &&
                          validation?.errors?.number
                            ? { borderColor: "red" }
                            : {}
                        }
                      />
                    </Col>

                    <Col sm={12} className="mb-3">
                      <Form.Label>Time Range</Form.Label>
                      <Select
                        options={dateRangeOptions}
                        value={dateRangeOptions.find(
                          (opt) => opt.value === validation.values.dateRange
                        )}
                        onChange={(option: any) => {
                          validation.setFieldValue(
                            "dateRange",
                            option ? option.value : ""
                          );
                          if (option?.value !== "custom") {
                            const endDate = moment();
                            const startDate = moment().subtract(
                              parseInt(option?.value),
                              "days"
                            );
                            validation.setFieldValue(
                              "startDate",
                              startDate.format("MM-DD-YYYY")
                            );
                            validation.setFieldValue(
                              "endDate",
                              endDate.format("MM-DD-YYYY")
                            );
                          }
                        }}
                        isClearable
                        styles={styleObj(
                          validation?.touched?.dateRange &&
                            validation?.errors?.dateRange
                        )}
                        theme={customSelectTheme}
                      />
                    </Col>

                    {validation.values.dateRange === "custom" && (
                      <Col sm={12} className="mb-3">
                        <Form.Label>Custom Date Range</Form.Label>
                        <Flatpickr
                          className="form-control"
                          options={{
                            mode: "range",
                            dateFormat: "m-d-Y",
                            onOpen: () => setDatePickerModal(true),
                            onClose: () => setDatePickerModal(false),
                            onChange: (dates: any) => {
                              if (dates.length === 2) {
                                validation.setFieldValue(
                                  "startDate",
                                  moment(dates[0]).format("MM-DD-YYYY")
                                );
                                validation.setFieldValue(
                                  "endDate",
                                  moment(dates[1]).format("MM-DD-YYYY")
                                );
                              }
                            },
                          }}
                        />
                      </Col>
                    )}
                  </Row>

                  <div className="d-flex align-items-center justify-content-end">
                    <Button
                      className="btn btn-secondary me-2"
                      type="button"
                      disabled={loading}
                      onClick={() => {
                        validation.resetForm();
                        handleGetData(currPage, rowsPerPage);
                      }}
                    >
                      Clear
                    </Button>
                    <Button
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                    >
                      {loading && <Spinner size="sm" animation="border" />}{" "}
                      Apply
                    </Button>
                  </div>
                </Form>
              </Dropdown.Menu>
            </Dropdown>
          }
        />

        <div className="position-relative">
          <Card className="wrapper">
            <Card.Body className="listing-table">
              {loading ? (
                <div>
                  <img
                    src={Loader}
                    className={`position-absolute top-50 start-50 translate-middle`}
                  />
                </div>
              ) : (
                <Datatable
                  data={
                    Array.isArray(shortLinkData?.records)
                      ? shortLinkData?.records
                      : []
                  }
                  columns={columns}
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  currPage={currPage}
                  rowsPerPage={rowsPerPage}
                  totalRecords={shortLinkData?.totalRecords}
                />
              )}
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};
export default ShortURL;
